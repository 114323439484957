import { FC, useEffect, useState } from "react";
import { Application } from "../../../../../types/Application";
import { SignatureHolderReadDTO } from "../../../../../types/Application/Signature";
import { SaveButton } from "../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../Buttons/UndoButton";
import RelativityCard from "../../../../Card/RelativityCard";
import { RelativityCardHeader } from "../../../../Card/RelativityCardHeader";
import Form from "../../../../Forms";
import { signatureFormSchema } from "../../../../Forms/ApplicationForm/Signature";
import { blankFormSchema } from "../../../../Forms/ApplicationForm/Signatures";
import { Loading } from "../../../../Loading/Loading";
import { showSuccessfulSaveAppToaster } from "../../../../Toast/Toast";
import { useSignature } from "../../../../Signatures/useSignature";
import { SignaturesValidationPanel } from "../../../../Signatures/SignaturesValidationPanel";
import { SaveChangeDialog } from "../../../../Dialogs/SaveChangeDialog";
export interface SignaturesPageCardProps {
  application: Application;
  submitForm: (signatures: SignatureHolderReadDTO[]) => Promise<void>;
  canEdit: boolean;
}
export const SignaturesPageCard: FC<SignaturesPageCardProps> = ({
  application,
  submitForm,
  canEdit,
}) => {
  const formId = "signatures-form";
  const sectionTitle = "Signatures";
  const cardClassName = "application-page signatures-card";

  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);

  const {
    ready,
    isSaving,
    setIsSaving,
    isDirty,
    setIsDirty,
    signatures,
    signaturesValidationDetails,
    fetchSignatures,
    handleSignatureFormSubmit,
  } = useSignature(application.id);

  useEffect(() => {
    (async () => {
      await fetchSignatures();
    })();
  }, [application?.signatures]);

  return (
    <RelativityCard
      className={cardClassName}
      headerContent={
        <RelativityCardHeader label={sectionTitle}>
          {ready && signatures?.length > 0 && (
            <div>
              {canEdit && (
                <>
                  <>
                    <SaveButton formId={formId} isSaving={isSaving} />
                    <UndoButton
                      isSaving={isSaving}
                      onClick={async () => {
                        setShouldResetForm(true);
                        // Reload
                        await fetchSignatures();
                        setShouldResetForm(false);
                      }}
                    />
                  </>
                </>
              )}
            </div>
          )}
        </RelativityCardHeader>
      }
    >
      <>
        {ready && (
          <>
            <SaveChangeDialog isDirty={isDirty} />
            <SignaturesValidationPanel
              signaturesValidationDetails={signaturesValidationDetails}
            />
            <section className="signatures-form">
              <Form<SignatureHolderReadDTO[]>
                formId={formId}
                value={{} as SignatureHolderReadDTO[]}
                disableAllFields={!canEdit}
                formSchemaBuilder={blankFormSchema}
                onFormSubmit={async () => {
                  setIsSaving(true);
                  await submitForm(signatures)
                    .then(() => {
                      showSuccessfulSaveAppToaster(sectionTitle);
                      setIsDirty(false);
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }}
              />
              {signatures?.map((signature, idx) => {
                return (
                  <div key={idx} className="signature-card">
                    <Form<SignatureHolderReadDTO>
                      formId={`signature-${idx}-form`}
                      value={signatures[idx]}
                      disableAllFields={!!isSaving || !canEdit}
                      shouldResetForm={shouldResetForm}
                      formSchemaBuilder={signatureFormSchema}
                      onFormSubmit={(incomingSignature) =>
                        handleSignatureFormSubmit(signature, incomingSignature)
                      }
                      isFormRequired={signature.isRequired}
                    />
                  </div>
                );
              })}
            </section>
          </>
        )}
        {!ready && <Loading />}
      </>
    </RelativityCard>
  );
};
