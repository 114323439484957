import { ExistingResource } from "./ExistingResource";

export interface NewBilling {
  applicationId?: string;
  corporateId: string;
  name: string;
  billingDate: string;
  invoiceNumber: number;
  invoiceNumber_AsString: string;
  invoiceId: string;
  amount: number;
  isCredit: boolean;
  billingRuleAmount: number;
  isShown: boolean;
  isDiscounted: boolean;
  canBeSingleExported: boolean;
  parentInvoiceId: string;
}

export type Billing = ExistingResource<NewBilling> & {
  changeNote: string;
};

export enum BillingTriggers {
  Approved = 1,
  RepHandoff = 2,
  MedicalEligibility = 3,
  PrivatePayCase = 4,
  OverAssetsCase = 5,
}
