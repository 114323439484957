import {
  FIARequestFilingProcessDocumentResult,
  StateNoticeFilingProcessDocumentResult,
} from "../../../components/DocumentFiling/types";
import baseApiClient from "../baseApiClient";

class DocumentFilingAIClient {
  fiaFilingProcessDocument = async (
    documentId: string
  ): Promise<FIARequestFilingProcessDocumentResult> => {
    return baseApiClient.get<FIARequestFilingProcessDocumentResult>(
      `filing-ai/fia/document/${documentId}`
    );
  };

  stateNoticeFilingProcessDocument = async (
    documentId: string
  ): Promise<StateNoticeFilingProcessDocumentResult> => {
    return baseApiClient.get<StateNoticeFilingProcessDocumentResult>(
      `filing-ai/state-notice/document/${documentId}`
    );
  };

  documentFilingDequeue = async (id: string): Promise<void> => {
    return baseApiClient.delete(`filing-ai/queue/${id}`);
  };

  getDocumentFilingQueueCount = async (): Promise<number> => {
    return baseApiClient.get<number>(`filing-ai/queue/count`);
  };
}

export const documentFilingAIClient = new DocumentFilingAIClient();
