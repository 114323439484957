import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { fiaRequestApiClient } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { FIAErrorMessage, genericErrorMessage } from "../../stores/ErrorStore";
import { userStore } from "../../stores/UserStore";
import { ExistingInstitution } from "../../types/Institution";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { downloadFile } from "../Document/PdfDocuments";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { Loading } from "../Loading/Loading";
import { AppToaster } from "../Toast/Toast";
import {
  FIARequest,
  FIARequestStatusTypes,
  FIARequestUpdateDTO,
} from "./configurations/types";
import { FIARequestDetails } from "./FIARequestDetails";
import { FIARequestDocuments } from "./FIARequestDocuments";
import { FIARequestInfo } from "./FIARequestInfo";
import { fiaRequestStore } from "./stores/FIARequestStore";
import {
  useFIARequestUpdate,
  validateFIARequestRequirements,
} from "./UseFIARequestFlyout";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { Footer } from "@ucl/library";
import { UploadDVTDocumentsCard } from "./UploadDVTDocumentsCard";

export interface FIARequestUpdateProps {
  applicationId: string;
  fiaRequestId: string;
  institution: ExistingInstitution | undefined;
  onActionExecuted: () => void;
  onRequestLoaded?: (fiaRequest?: FIARequest) => void;
  onActionExecuting?: (isExecuting: boolean) => void;
  setFIARequestOnApproveOrSend: (fiaRequest: FIARequest) => void;
}

export const FIARequestUpdate: React.FC<FIARequestUpdateProps> = (props) => {
  const {
    isSaving,
    ready,
    catchPreviewFIARequestErrors,
    isActionExecuting,
    setIsActionExecuting,
    fiaRequest,
    setFIARequest,
    errors,
    documentErrors,
    setDocumentErrors,
    setErrors,
    isResendLoading,
    isPreviewLoading,
    setIsPreviewLoading,
    isViewSentFIALoading,
    setIsViewSentFIALoading,
    isCancelLoading,
    setIsCancelLoading,
    isMarkAsReceivedLoading,
    setIsMarkAsReceivedLoading,
    canSendFIARequest,
    isMarkAsNotReceivedLoading,
    setIsMarkAsNotReceivedLoading,
    approveAndSend,
    openResendDialog,
  } = useFIARequestUpdate(
    props.applicationId,
    props.fiaRequestId,
    props.institution,
    props.setFIARequestOnApproveOrSend,
    props.onRequestLoaded,
    props.onActionExecuting
  );

  return (
    <div className="fia-request-update">
      {ready && (
        <>
          <BaseForm<FIARequestUpdateDTO>
            key={fiaRequest.id}
            formId="fia-request-update-form"
            isDisabled={isActionExecuting}
            onFormSubmit={async (updatedFIARequest: FIARequestUpdateDTO) => {
              await approveAndSend(updatedFIARequest);
            }}
            value={fiaRequest}
            formValueState={{
              formValue: fiaRequest,
              setFormValue: setFIARequest,
            }}
            onErrors={(errors) => setErrors(errors)}
          >
            <FIARequestInfo fiaRequest={fiaRequest} showStatusTag={false} />
            <FIARequestDetails
              fiaRequest={fiaRequest}
              applicationId={props.applicationId}
              validateFIARequestRequirements={() =>
                validateFIARequestRequirements(
                  props.applicationId,
                  props.fiaRequestId,
                  fiaRequest,
                  setErrors,
                  setDocumentErrors
                )
              }
              errors={errors}
              setIsActionExecuting={setIsActionExecuting}
              setFIARequest={setFIARequest}
              institution={props.institution}
            />
            <FIARequestDocuments
              canEditFIARequestDocumentList={
                !!userStore.user?.canEditFIARequestDocumentList
              }
              applicationId={props.applicationId}
              fiaRequest={fiaRequest}
              isDisabled={isActionExecuting}
              errors={errors}
              documentErrors={documentErrors}
              catchPreviewFIARequestErrors={catchPreviewFIARequestErrors}
              validateFIARequestRequirements={() =>
                validateFIARequestRequirements(
                  props.applicationId,
                  props.fiaRequestId,
                  fiaRequest,
                  setErrors,
                  setDocumentErrors
                )
              }
            />
            {fiaRequest.requestStatus == FIARequestStatusTypes.Submitted &&
              userStore.user
                ?.canEditDVTUploadsChecklistItemDocumentOnFIARequest && (
                <UploadDVTDocumentsCard />
              )}
          </BaseForm>
          <Footer>
            <div className="fia-request-footer-panel">
              {userStore.user?.canCancelFIARequest && fiaRequest.canBeCanceled && (
                <Button
                  className="fia-request-footer-action__cancel"
                  minimal
                  intent={Intent.DANGER}
                  disabled={isActionExecuting}
                  loading={isCancelLoading}
                  text="Cancel Request"
                  rightIcon={IconNames.Cross}
                  onClick={async () => {
                    dialogsViewerStore.setIsFIARequestCancelDialogOpen(true, {
                      applicationId: props.applicationId,
                      fiaRequestId: fiaRequest.id,
                      setIsCancelLoading: setIsCancelLoading,
                      onSuccessfulCancel: () => {
                        props.onActionExecuted();
                      },
                    });
                    setIsCancelLoading(true);
                  }}
                />
              )}
            </div>
            <div className="fia-request-footer-panel">
              {fiaRequest.requestStatus === FIARequestStatusTypes.Canceled && (
                <Button
                  className="fia-request-footer-action__cancel"
                  minimal
                  intent={Intent.DANGER}
                  disabled={isActionExecuting}
                  loading={isCancelLoading}
                  text="Cancel Reasons"
                  onClick={async () => {
                    dialogsViewerStore.setIsFIARequestCancelDialogOpen(true, {
                      applicationId: props.applicationId,
                      fiaRequestId: fiaRequest.id,
                      setIsCancelLoading: setIsCancelLoading,
                      readOnly: true,
                      cancelReasons: fiaRequest.cancelReasons_AsList,
                      onSuccessfulCancel: () => {
                        props.onActionExecuted();
                      },
                    });
                  }}
                />
              )}
              {userStore.user?.canMarkFIARequestAsReceived &&
                fiaRequest.canBeMarkAsReceived && (
                  <Button
                    className="fia-request-footer-action__markAsReceived"
                    minimal
                    intent={Intent.PRIMARY}
                    disabled={isActionExecuting}
                    loading={isMarkAsReceivedLoading}
                    text="Mark As Received"
                    rightIcon={IconNames.Tick}
                    onClick={async () => {
                      setIsMarkAsReceivedLoading(true);

                      await fiaRequestStore
                        .markAsReceived(props.applicationId, props.fiaRequestId)
                        .then(() => {
                          props.onActionExecuted();
                        })
                        .catch(() => {
                          AppToaster.show({
                            message: <div>{genericErrorMessage}</div>,
                            intent: Intent.DANGER,
                          });
                        })
                        .finally(() => {
                          setIsMarkAsReceivedLoading(false);
                        });
                    }}
                  />
                )}
              {userStore.user?.canMarkFIARequestAsNotReceived &&
                fiaRequest.canBeMarkAsNotReceived && (
                  <Button
                    className="fia-request-footer-action__markAsReceived"
                    minimal
                    intent={Intent.DANGER}
                    disabled={isActionExecuting}
                    loading={isMarkAsNotReceivedLoading}
                    text="Mark As Not Received"
                    rightIcon={IconNames.Cross}
                    onClick={async () => {
                      setIsMarkAsNotReceivedLoading(true);

                      await fiaRequestStore
                        .markAsNotReceived(
                          props.applicationId,
                          props.fiaRequestId
                        )
                        .then(() => {
                          props.onActionExecuted();
                        })
                        .catch(() => {
                          AppToaster.show({
                            message: <div>{genericErrorMessage}</div>,
                            intent: Intent.DANGER,
                          });
                        })
                        .finally(() => {
                          setIsMarkAsNotReceivedLoading(false);
                        });
                    }}
                  />
                )}
              {fiaRequest.lastSentFIARequestDocumentId && (
                <Button
                  className="fia-request-footer-action__preview"
                  minimal
                  intent={Intent.PRIMARY}
                  disabled={isActionExecuting}
                  loading={isViewSentFIALoading}
                  text="View Sent FIA Request"
                  onClick={async () => {
                    setIsViewSentFIALoading(true);
                    const updatedFiaRequest =
                      await fiaRequestApiClient.getRequest(
                        props.applicationId,
                        props.fiaRequestId
                      );
                    if (
                      (updatedFiaRequest as FIARequestUpdateDTO)
                        .lastSentFIARequestDocumentId
                    ) {
                      dialogsViewerStore.openDocumentViewerDialog({
                        key: props.fiaRequestId,
                        getDocument: documentApiClient.downloadDocument(
                          String(
                            (updatedFiaRequest as FIARequestUpdateDTO)
                              .lastSentFIARequestDocumentId
                          )
                        ),
                      });
                      catchPreviewFIARequestErrors(
                        dialogsViewerStore.documentViewerDialogOptions
                          ?.getDocument,
                        setErrors
                      );
                    } else {
                      AppToaster.show({
                        message: <div>{genericErrorMessage}</div>,
                        intent: Intent.DANGER,
                      });
                    }
                    setIsViewSentFIALoading(false);
                  }}
                />
              )}
              {userStore.user?.canPreviewFIARequest && (
                <Button
                  className="fia-request-footer-action__preview"
                  minimal
                  intent={Intent.PRIMARY}
                  disabled={isActionExecuting}
                  loading={isPreviewLoading}
                  text={
                    fiaRequest.shouldDownloadInsteadOfPreview
                      ? "Download"
                      : "Generate Preview"
                  }
                  onClick={async () => {
                    setIsPreviewLoading(true);
                    if (!fiaRequest.shouldDownloadInsteadOfPreview) {
                      dialogsViewerStore.openDocumentViewerDialog({
                        key: props.fiaRequestId,
                        getDocument: fiaRequestApiClient.previewFIARequest(
                          props.applicationId,
                          props.fiaRequestId
                        ),
                      });
                      setIsPreviewLoading(false);
                      catchPreviewFIARequestErrors(
                        dialogsViewerStore.documentViewerDialogOptions
                          ?.getDocument,
                        setErrors
                      );
                    } else {
                      await fiaRequestApiClient
                        .previewFIARequest(
                          props.applicationId,
                          String(fiaRequest.id)
                        )
                        .then((blob) => {
                          downloadFile(
                            blob as BlobPart,
                            `${fiaRequest.id}.pdf`
                          );
                        })
                        .catch((error) => {
                          if (error?.response.status == 400) {
                            setErrors(error.response.data.additionalInfo);
                          } else {
                            AppToaster.show({
                              message: <div>{FIAErrorMessage}</div>,
                              intent: Intent.DANGER,
                            });
                          }
                        })
                        .finally(() => setIsPreviewLoading(false));
                    }
                  }}
                />
              )}

              {canSendFIARequest &&
                fiaRequest.canBeResent &&
                !fiaRequest.hasInvalidFaxInformation &&
                !fiaRequest.hasInvalidEmailInformation && (
                  <Button
                    className="fia-request-footer-action__resend"
                    minimal
                    intent={Intent.PRIMARY}
                    disabled={isActionExecuting}
                    loading={isResendLoading}
                    text="Resend"
                    onClick={async () => {
                      openResendDialog(props.onActionExecuted);
                    }}
                  />
                )}
              {canSendFIARequest &&
                fiaRequest.canBeApprovedAndSent &&
                !fiaRequest.canApproveOnly &&
                !fiaRequest.hasInvalidFaxInformation &&
                !fiaRequest.hasInvalidEmailInformation && (
                  <FormSubmitButton
                    buttonId={`fia-request-footer-action-approve-send`}
                    isSaving={isSaving}
                    formId={`fia-request-update-form`}
                    text={"Send"}
                  />
                )}
              {canSendFIARequest && fiaRequest.canApproveOnly && (
                <FormSubmitButton
                  buttonId={`fia-request-footer-action-approve`}
                  isSaving={isSaving}
                  formId={`fia-request-update-form`}
                  text={"Approve"}
                />
              )}
            </div>
          </Footer>
        </>
      )}
      {!ready && <Loading />}
    </div>
  );
};
