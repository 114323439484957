import {
  PdfDocumentEntityType,
  PdfDocumentUploader,
} from "../Document/PdfDocumentUploader";
import useDocumentFiling, {
  DocumentFilingEvents,
  FilingStrategy,
} from "./useDocumentFiling";
import { CancelButton, Footer, PrimaryButton, PrimaryGrid } from "@ucl/library";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { Button, Classes, Tag } from "@blueprintjs/core";
import { getObjectValueActiveClassName } from "../../utils/miscellaneous";
import { DocumentPreview } from "../Document/DocumentPreview";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { FIAFilingFormCard } from "./FIAFilingFormCard";
import { StateNoticeFilingFormCard } from "./StateNoticeFilingFormCard";
import { IconNames } from "@blueprintjs/icons";
import { WithTooltip } from "../Tooltip/Tooltip";
import { AppToaster } from "../Toast/Toast";
import baseApiClient, {
  getAuthRequestHeader,
} from "../../lib/apiClients/baseApiClient";
import variables from "../../config/variables";
import {
  DocumentFilingQueueMetricReadDTO,
  DocumentFilingQueueODataReadDTO,
} from "./types";
import { documentFilingQueueGridColumnsConfiguration } from "./configurations/documentFilingQueueGridColumnsConfiguration";
import { debounce } from "lodash";

interface DocumentFilingComponentProps extends DocumentFilingEvents {
  onCancel: () => void;
}
export const DocumentFilingComponent: React.FC<DocumentFilingComponentProps> = (
  props
) => {
  const {
    filingAIEntityId,
    document,
    setDocument,
    stages,
    fiaRequest,
    associatedChecklistItem,
    fiaSearchAsyncAutoCompleteFieldProps,
    asyncAutoCompleteProps,
    setAsyncAutoCompleteProps,
    canBeFiled,
    fiaProcessDocumentResult,
    searchValue,
    autoFillData,
    filingStrategy,
    setFilingStrategy,
    stateNoticeFilingProcessDocumentResult,
    application,
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    documentVerifyDTO,
    setDocumentVerifyDTO,
    resetDocumentFIling,
    newDocumentName,
    setNewDocumentName,
    isSaving,
    handleSubmitDocument,
    errors,
    documentErrors,
    fiaNeedsFollowUp,
    documentFilingQueueGridRef,
    documentFilingQueueGridRefresh,
    documentFilingQueueGridContextMenuItems,
    documentFilingQueueGridContextMenuClick,
    documentFilingQueueGridRecordClick,
  } = useDocumentFiling(props);

  return (
    <>
      <div className={`${Classes.DIALOG_BODY} fiafiling-container`}>
        <section className="fiafiling-content">
          <div className="document-filing-stages">
            <div
              className={`filing-stage ${getObjectValueActiveClassName(true)}`}
            >
              <Tag className="filing-stage-tag" round={true}>
                1
              </Tag>
              <span className="filing-stage-text">Upload Document</span>
              <div className="filing-stage-border"></div>
            </div>
            <div
              className={`filing-stage ${getObjectValueActiveClassName(
                stages.isFormStage
              )}`}
            >
              <Tag className="filing-stage-tag" round={true}>
                2
              </Tag>
              <span className="filing-stage-text">Verify & File</span>
              <div className="filing-stage-border"></div>
            </div>
          </div>
          {stages.isFormStage && (
            <Button
              className="document-filing-back-button"
              icon={IconNames.ArrowLeft}
              text="Back to File Uploader"
              onClick={resetDocumentFIling}
            />
          )}
          {stages.isUploadDocumentStage && (
            <div className="fiafiling-content-upload">
              <div className="welcome">
                Please upload FIA Request and/or State Notice documents by
                dragging and dropping into the respective area below or clicking
                on the upload button.
              </div>
              <div className="document-filing-uploaders-container">
                <div
                  id="fia-uploader"
                  className="fiafiling-content-upload-type"
                >
                  <div className="fiafiling-content-upload-type-text fia">
                    FIA
                  </div>
                  <PdfDocumentUploader
                    allowMultipleFiles={false}
                    pdfDocumentEntity={{
                      entityId: filingAIEntityId,
                      entityType: PdfDocumentEntityType.FilingAI,
                    }}
                    onUploading={() => setIsUploadingFiaDoc(true)}
                    onCompleteUploading={(doc) => {
                      setDocument(doc);
                      setFilingStrategy(FilingStrategy.FIADocument);
                      setAsyncAutoCompleteProps(
                        fiaSearchAsyncAutoCompleteFieldProps
                      );
                      setIsUploadingFiaDoc(false);
                      props?.onDocumentUploaded?.();
                    }}
                    onCompleteRemoving={() => {
                      setDocument(undefined);
                      setFilingStrategy(undefined);
                      setIsUploadingFiaDoc(false);
                    }}
                    disabled={isUploadingStateNoticeDoc}
                  />
                </div>
                <div id="state-notice-uploader">
                  <div className="fiafiling-content-upload-type">
                    <div className="fiafiling-content-upload-type-text state-notice">
                      State Notices
                    </div>
                    <PdfDocumentUploader
                      allowMultipleFiles={true}
                      pdfDocumentEntity={{
                        entityId: undefined,
                        entityType: PdfDocumentEntityType.FilingAI,
                      }}
                      onUploading={() => setIsUploadingStateNoticeDoc(true)}
                      onAllActionComplete={(args, ref) => {
                        if (args && ref) {
                          ref.clearAll();
                          AppToaster.show({
                            message:
                              "State Notice Documents Uploaded Successfully",
                            intent: "success",
                          });
                          setIsUploadingStateNoticeDoc(false);
                        }
                      }}
                      onCompleteUploading={() => {
                        props?.onDocumentUploaded?.();

                        debounce(() => {
                          documentFilingQueueGridRefresh();
                        }, 1000);
                      }}
                      onCompleteRemoving={() => {
                        setDocument(undefined);
                        setFilingStrategy(undefined);
                        setIsUploadingStateNoticeDoc(false);
                      }}
                      disabled={isUploadingFiaDoc}
                    />
                  </div>
                  <div className="fiafiling-content-queue">
                    <div className="fiafiling-content-queue-header">
                      <h2>State Notices Waiting to be Filed</h2>
                      <Button
                        icon={IconNames.Refresh}
                        minimal={true}
                        onClick={documentFilingQueueGridRefresh}
                      />
                    </div>
                    <PrimaryGrid<
                      DocumentFilingQueueODataReadDTO,
                      DocumentFilingQueueMetricReadDTO
                    >
                      gridKey={"document-filing-queue"}
                      ref={documentFilingQueueGridRef}
                      apiClient={baseApiClient}
                      odataUrl={`${variables.apiBaseUrl}odata/documentfilingqueueodata`}
                      getAuthRequestHeader={getAuthRequestHeader}
                      columnsConfiguration={
                        documentFilingQueueGridColumnsConfiguration
                      }
                      viewsSettings={{
                        showGridViewPicker: false,
                        gridTitle: "Document Filing Queue",
                        shouldCreateAutoView: true,
                      }}
                      showGridFilters={false}
                      gridConfiguration={{
                        allowRowDragAndDrop: false,
                        contextMenuItems:
                          documentFilingQueueGridContextMenuItems,
                        contextMenuClick:
                          documentFilingQueueGridContextMenuClick,
                        allowPaging: true,
                        enableInfiniteScrolling: false,
                        pageSettings: { pageSize: 5 },
                        allowExcelExport: false,
                        allowResizing: false,
                        allowSelection: false,
                        onRecordClick: documentFilingQueueGridRecordClick,
                      }}
                      allowSearch={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {stages.isProcessDocumentStage && (
            <div className="fiafiling-process-document">
              <SkeletonComponent
                className="loading-skeleton"
                type="rectangle"
                width={500}
                height={25}
              >
                Processing document...
              </SkeletonComponent>
            </div>
          )}
          <div
            className="fiafiling-content-form"
            // Issue: AsyncAutoCompleteField is not rendering correctly if not loaded in the first render
            // Rendering form, but not showing until is form stage
            style={{ display: stages.isFormStage ? "flex" : "none" }}
            key={
              (autoFillData && autoFillData.length > 0) || searchValue
                ? "search"
                : "id"
            }
          >
            <div className="filing-document-panel">
              {document && (
                <DocumentPreview
                  key={document.id}
                  getDocument={documentApiClient.downloadDocument(document.id)}
                />
              )}
            </div>

            <div className="filing-form-panel">
              {filingStrategy === FilingStrategy.FIADocument && (
                <FIAFilingFormCard
                  asyncAutoCompleteProps={asyncAutoCompleteProps}
                  document={document}
                  newDocumentName={newDocumentName}
                  setNewDocumentName={setNewDocumentName}
                  fiaProcessDocumentResult={fiaProcessDocumentResult}
                  fiaRequestResult={fiaRequest}
                  associatedChecklistItem={associatedChecklistItem}
                  autoFillData={autoFillData}
                  searchValue={searchValue}
                  isSaving={isSaving}
                  fiaRequestVerifyDTO={documentVerifyDTO}
                  setFiaRequestVerifyDTO={setDocumentVerifyDTO}
                  errors={errors}
                  documentErrors={documentErrors}
                  fiaNeedsFollowUp={fiaNeedsFollowUp}
                />
              )}
              {filingStrategy === FilingStrategy.StateNoticeDocument && (
                <StateNoticeFilingFormCard
                  asyncAutoCompleteProps={asyncAutoCompleteProps}
                  document={document}
                  newDocumentName={newDocumentName}
                  setNewDocumentName={setNewDocumentName}
                  stateNoticeFilingProcessDocumentResult={
                    stateNoticeFilingProcessDocumentResult
                  }
                  application={application}
                  associatedChecklistItem={associatedChecklistItem}
                  autoFillData={autoFillData}
                  searchValue={searchValue}
                  isSaving={isSaving}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer>
        <CancelButton onClick={props.onCancel} />
        {stages.isFormStage && (
          <WithTooltip
            shouldShowTooltip={!canBeFiled}
            content="Mark FIA Request as Received or Resend before filing the document"
          >
            <PrimaryButton
              content="File Document"
              disabled={!canBeFiled}
              isLoading={isSaving}
              loadingProgressContent="Submitting..."
              loadingSuccessContent="Submitted"
              onClick={async () => {
                await handleSubmitDocument();
              }}
            />
          </WithTooltip>
        )}
      </Footer>
    </>
  );
};
