import { AddressFieldValue } from "../components/Fields/types/fieldTypes";
import { FacilityUser } from "./FacilityUser";
import { UserPermission } from "./Permission";

export interface NewUser {
  firstName: string;
  lastName: string;
  corporateId: string;
  facilityIds: string[];
  facilities: FacilityUser[];
  address: AddressFieldValue;
  phoneNumber: string;
  phoneExtension: string;
  additionalPhoneNumber: string;
  faxNumber: string;
  email: string;
  role: number;
  regions: number[];
  userPermissions: UserPermission[];
  isActive: boolean;
  fieldRepFacilityIds: string[];
}

export enum UserRoles {
  Administrator = 1,
  FieldRepresentative = 2,
  FieldRepresentativeManager = 3,
  Processor = 4,
  ProcessingManager = 5,
  DVTProcessor = 6,
  DVTManager = 7,
  Accountant = 9,
  ITHelpDesk = 10,
  Director = 11,
  OfficeStaff = 13,
  FacilityUser = 14,
  CorporateUser = 15,
  RecertProcessor = 16,
  HybridRepProcessor = 17,
}

export type UserSignatures = {
  signature: string;
  signature2: string;
  signature3: string;
  signature4: string;
  signature5: string;
  signature6: string;
};

export type User = {
  id: string;
  externalId: string;
  username: string;
  firstName: string;
  fullName: string;
  lastName: string;
  corporateId: string;
  facilityIds: string[];
  facilities: FacilityUser[];
  address: AddressFieldValue;
  regions: number[];
  phoneNumber: string;
  phoneExtension: string;
  additionalPhoneNumber: string;
  faxNumber: string;
  email: string;
  userInviteEmailSentDate: Date;
  title: string;
  role: UserRoles;
  role_AsString: string;
  userPermissions: UserPermission[];
  isActive: boolean;
  isExternal: boolean;
  signature: string;
  signature2: string;
  signature3: string;
  signature4: string;
  signature5: string;
  signature6: string;
  fieldRepFacilityIds: string[];
  lastLoginDate: Date;
  fieldRepTeams_AsList: number[];
} & Permission;

export interface Permission {
  canViewBoardMenu: boolean;
  canViewReportsMenu: boolean;
  canViewTemplatesMenu: boolean;
  canViewAdminMenu: boolean;
  canViewAllApplications: boolean;
  canViewApplicationsWithInRegions: boolean;
  canViewApplicationsWithInCorporate: boolean;
  canViewApplicationsWithInFacility: boolean;
  canViewAssignedApplication: boolean;
  canCreateApplication: boolean;
  canExportApplicationBoardToExcel: boolean;
  canConfigureApplicationBoard: boolean;

  // Reports Permissions
  canViewResidentReport: boolean;
  canViewFIAReport: boolean;
  canViewFaxErrorReport: boolean;
  canViewBillingExceptionsReport: boolean;
  canViewAuditReport: boolean;
  canViewFacilityReport: boolean;
  canViewCorporateReport: boolean;
  canViewContractReport: boolean;
  canViewRecertProductionReport: boolean;
  canViewRecertsTimeTrackingReport: boolean;
  canViewDVTTimeTrackingReport: boolean;
  canViewTimeTrackingReport: boolean;
  canViewComplianceMonitoringReport: boolean;
  canViewOversight90PlusTrackingReport: boolean;
  canView90PlusTrackingReport: boolean;

  canCreateTemplate: boolean;
  canExportTemplateGridToExcel: boolean;
  canConfigureTemplateBoard: boolean;
  canEditTemplate: boolean;
  canViewUserBoard: boolean;
  canViewCorporateBoard: boolean;
  canViewFacilityBoard: boolean;
  canViewInstitutionBoard: boolean;
  canViewBillingBoard: boolean;
  canViewInvoiceBoard: boolean;
  canCreateUser: boolean;
  canEditUser: boolean;
  canExportUserBoardToExcel: boolean;
  canConfigureUserBoard: boolean;
  canCreateCorporate: boolean;
  canExportCorporateBoardToExcel: boolean;
  canConfigureCorporateBoard: boolean;
  canEditCorporate: boolean;
  canEditBillingRule: boolean;
  canExportBillingRuleToExcel: boolean;
  canCreateDiscount: boolean;
  canEditDiscount: boolean;
  canExportDiscountToExcel: boolean;
  canCreateFacility: boolean;
  canExportFacilityToExcel: boolean;
  canConfigureFacilityBoard: boolean;
  canEditFacility: boolean;
  canViewFacilityContact: boolean;
  canCreateInstitution: boolean;
  canExportInstitutionToExcel: boolean;
  canConfigureInstitutionBoard: boolean;
  canEditInsitution: boolean;
  canEditInstitutionContact: boolean;
  canEditInstitutionDocsNeeded: boolean;
  canCreateBill: boolean;
  canGenerateInvoice: boolean;
  canExportBillingToExcel: boolean;
  canConfigureBillingBoard: boolean;
  canEditBill: boolean;
  canExportInvoiceToExcel: boolean;
  canConfigureInvoiceBoard: boolean;
  canViewInvoiceDocument: boolean;
  canSendInvoice: boolean;
  canRegenerateInvoiceDocument: boolean;
  canWithdrawApplicationForm: boolean;
  canSubmitApplicationForm: boolean;
  canSaveApplicationForm: boolean;
  canMonitorApplicationPage: boolean;
  canSubmitApplicationToState: boolean;
  canGenerateApplicationDocPackage: boolean;
  canEditApplicationPageFileInformation: boolean;
  canEditApplicationPageDateInformation: boolean;
  canEditApplicationPageStateInformation: boolean;
  canEditApplicationPagePrimaryInformation: boolean;
  canEditApplicationPageSecondaryInformation: boolean;
  canCreateChildApplication: boolean;
  canCreateApplicationPageContact: boolean;
  canEditApplicationPageContact: boolean;
  canEditApplicationPageMedicalInformation: boolean;
  canCreateApplicationPageMedicalCoverage: boolean;
  canEditApplicationPageMedicalCoverage: boolean;
  canEditApplicationPageSpouse: boolean;
  canCreateApplicationPageIncome: boolean;
  canEditApplicationPageIncome: boolean;
  canCreateApplicationPageAsset: boolean;
  canEditApplicationPageAsset: boolean;
  canCreateApplicationPageBill: boolean;
  canEditApplicationPageBill: boolean;
  canEditApplicationPageLegalInformation: boolean;
  canEditApplicationPageSignature: boolean;
  canUndoApplicationPageSignature: boolean;
  canClearApplicationPageSignature: boolean;
  canViewChecklist: boolean;
  canGenerateChecklist: boolean;
  canCreateChecklist: boolean;
  canSplitChecklist: boolean;
  canViewFIARequest: boolean;
  canCreateFIARequest: boolean;
  canEditFIARequest: boolean;
  canEditFIARequestDocumentList: boolean;
  canCancelFIARequest: boolean;
  canMarkFIARequestAsReceived: boolean;
  canMarkFIARequestAsNotReceived: boolean;
  canSubmitFIARequest: boolean;
  canPreviewFIARequest: boolean;
  canViewComment: boolean;
  canCreateComment: boolean;
  canEditSelfComment: boolean;
  canDeleteSelfComment: boolean;
  canEditAllComment: boolean;
  canDeleteComment: boolean;
  canViewInternalComment: boolean;
  canViewPublicComment: boolean;
  canViewNotaryBoard: boolean;
  canCreateNotary: boolean;
  canExportNotaryGridToExcel: boolean;
  canConfigureNotaryBoard: boolean;
  canEditNotary: boolean;
  canEditFacilityOnApplicationPage: boolean;
  canViewApplicationDetail: boolean;
  canWithdrawApplicationPage: boolean;
  canEditRepHandoffDate: boolean;
  canEditClosedDate: boolean;
  canEditMonitorEndDate: boolean;
  canEditProcessorAssignedToApplication: boolean;
  canEditFieldRepAssignedToApplication: boolean;
  canEditApplicationChecklistItemStatus: boolean;
  canEditApplicationWhenClosed: boolean;
  canPinComment: boolean;
  canEditMedicaidNeededDate: boolean;
  canEditResidentIDAvailable: boolean;
  canEditResidentIDNumber: boolean;
  canCreateNewChildWhileClosed: boolean;
  canGenerateDocsWhileClosed: boolean;
  canAddCommentWhileClosed: boolean;
  canEditNextRecertDateWhileClosed: boolean;
  canEditRecertNeededWhileClosed: boolean;
  canEditChecklistWhileClosed: boolean;
  canSendSpecializedFIA: boolean;
  canEditRepUploadsChecklistItemDocument: boolean;
  canEditDVTUploadsChecklistItemDocument: boolean;
  canEditRegularApplicationChecklistItemDocument: boolean;
  canConvertApplicationToRecert: boolean;
  canEditDVTUploadsChecklistItemDocumentOnFIARequest: boolean;
  canViewMatchupMenu: boolean;
  canManageHelpCenterTopics: boolean;

  // Application Status Permissions
  canAccessApplicationInterviewingStatus: boolean;
  canAccessApplicationInSchedulingStatus: boolean;
  canAccessApplicationRemainningStatus: boolean;

  // Application Reason Code Permissions
  canAccessApplicationApprovedReasonCode: boolean;
  canAccessApplicationRemainningReasonCode: boolean;

  // Application Product Type Permissions
  canAccessApplicationNewApplicationProductType: boolean;
  canAccessApplicationNewRecertificationProductType: boolean;
  canAccessApplicationRecertificationProductType: boolean;
  canAccessApplicationConversionToLtcProductType: boolean;
  canAccessApplicationLiabilityAdjustmentProductType: boolean;
  canAccessApplicationRemainningProductType: boolean;

  canViewCancelAllFIARequestsButton: boolean;
  canViewHelpCenter: boolean;
  canViewGooseChat: boolean;
  canOverrideAssetEligibility: boolean;
  canEditApplicationNotary: boolean;
  canEditClosedApplicationApprovedDate: boolean;
  canEditProcessorAssignedToClosedApplication: boolean;
  canManageReports: boolean;
  canEditMedicaidNeededDateOnlyInInterviewingStatus: boolean;
  canEditMedicaidNeededDateInAnyStatus: boolean;
  canImportMatchups: boolean;
  canEditAssetExempt: boolean;
  canEditAssetVerified: boolean;
  canBypassFIARequestApproveAndSend: boolean;
  canViewScheduleMenu: boolean;
  canScheduleAppointment: boolean;
  canVerifyDocument: boolean;
  canViewFIARequestGrid: boolean;
  canEditMedicaidNeededDateOnClosedApplication: boolean;
  canEditFacilityOnInterviewingApplication: boolean;
  canEditIDFieldsAndPersonallyKnownOnContact: boolean;

  // Checklist Document
  canEditPoaDocumentChecklistItemDocument: boolean;

  canEditPoaIdChecklistItemDocument: boolean;

  canEditSpousePoaDocumentChecklistItemDocument: boolean;

  canEditSpousePoaIdChecklistItemDocument: boolean;

  canEditGuardianDocumentChecklistItemDocument: boolean;

  canEditGuardianIdChecklistItemDocument: boolean;
  canAccessFilingAI: boolean;
  canEditOverAssetPrivatePayFields: boolean;
}
