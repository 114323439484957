import { FC, useRef, useState } from "react";
import { useFieldSchemaFactory } from "../../../../lib/fieldSchemaV2/useFieldSchemaV2Factory";
import {
  FieldFormV2,
  FieldFormV2Module,
  FieldLabel,
  FieldV2,
  FormFieldErrorContainer,
} from "@ucl/library";
import { BlockScheduleFormModel } from "./BlockScheduleFormModel";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { getBlockScheduleFormSchema } from "./BlockScheduleFormSchema";
import { Loading } from "../../../Loading/Loading";
import "./styles.scss";
import { RecurringFrequencyTypeOptions } from "./types/RecurringFrequencyTypeOptions";
import schedulingApiClient from "../../../../lib/apiClients/scheduling/schedulingApiClient";
import { AppToaster } from "../../../Toast/Toast";
import { get } from "lodash";

export const defaultBlockScheduleFormModel = (): BlockScheduleFormModel => {
  const today = new Date();
  return {
    id: undefined,
    facilityId: undefined,
    fieldRepId: undefined,
    availableTimeSlot: undefined,
    repeatSchedule: undefined,
    recurringFrequencyType: undefined,
    recurringOrdinalDays_AsList: undefined,
    recurringDays_AsList: undefined,
    startDate: `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`,
    endDate: undefined,
  };
};

interface BlockScheduleFormProps {
  blockIndex: number;
  formModel?: BlockScheduleFormModel;
  shouldDisableForm?: boolean;
  onDelete?: () => void;
}

export const BlockScheduleForm: FC<BlockScheduleFormProps> = (props) => {
  const formRef = useRef<FieldFormV2Module<BlockScheduleFormModel>>(null);

  const { schemaFactory } = useFieldSchemaFactory();
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [blockScheduleFormModel, setBlockScheduleFormModel] =
    useState<BlockScheduleFormModel>({
      ...defaultBlockScheduleFormModel(),
      ...props.formModel,
    });

  const blockScheduleId = blockScheduleFormModel?.id;
  const facilityId = blockScheduleFormModel?.facilityId;
  const fieldRepId = blockScheduleFormModel?.fieldRepId;

  const isEditing = !!blockScheduleId && !!facilityId && !!fieldRepId;
  const formDisabled = props.shouldDisableForm || isSubmitting || isDeleting;

  const handleDelete = async () => {
    if (isEditing) {
      setIsDeleting(true);
      await schedulingApiClient
        .deleteBlockSchedule(facilityId, fieldRepId, blockScheduleId)
        .catch((error) => {
          AppToaster.show({
            message:
              "Unexpected error occurred while deleting block schedule entry",
            intent: Intent.DANGER,
          });
          console.error(error);
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
    props.onDelete?.();
  };

  const handleFormSubmit = async (form: BlockScheduleFormModel) => {
    setIsSubmitting(true);

    await (isEditing
      ? schedulingApiClient.updateBlockSchedule(form)
      : schedulingApiClient.createBlockSchedule(form)
    )
      .then((response) => {
        setBlockScheduleFormModel(response);
        setErrors({});
        AppToaster.show({
          message: `Block schedule entry ${
            isEditing ? "updated" : "created"
          } successfully`,
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: `Unexpected error occurred while ${
              isEditing ? "updating" : "creating"
            } block schedule entry`,
            intent: Intent.DANGER,
          });
        }
        const errorResponse = error?.response?.data?.additionalInfo;
        if (errorResponse) {
          setErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!schemaFactory) {
    return <Loading />;
  }

  const schema = getBlockScheduleFormSchema(
    schemaFactory,
    blockScheduleFormModel,
    setBlockScheduleFormModel
  );

  return (
    <div className="block-schedule-form">
      <div className="block-schedule-form_header">
        <div className="block-schedule-form_header_title">
          Block {props.blockIndex + 1}
        </div>
        <div className="block-schedule-form_header_actions">
          <Button
            minimal
            intent={Intent.PRIMARY}
            rightIcon={IconNames.FLOPPY_DISK}
            disabled={formDisabled}
            onClick={formRef.current?.submit}
          />
          <Button
            minimal
            intent={Intent.DANGER}
            rightIcon={IconNames.TRASH}
            disabled={formDisabled}
            onClick={async () => await handleDelete()}
          />
        </div>
      </div>
      <FieldFormV2<BlockScheduleFormModel>
        ref={formRef}
        value={blockScheduleFormModel}
        onFormSubmit={handleFormSubmit}
        isDisabled={props.shouldDisableForm || isSubmitting || isDeleting}
      >
        <div className="block-schedule-form_section time-section">
          <FieldV2 fieldProps={schema.AvailableTimeSlot} />
          <FormFieldErrorContainer
            errorMessages={get(errors, "availableTimeSlot", [])}
          />
          {!blockScheduleFormModel.repeatSchedule && (
            <FieldV2 fieldProps={schema.StartDate} />
          )}
        </div>
        <div className="block-schedule-form_section repeat-switch-section">
          <FieldV2 fieldProps={schema.RepeatSchedule} />
        </div>
        <div className="block-schedule-form_section recurrence-section">
          <div className="recurrence-section_main">
            <FieldV2 fieldProps={schema.RecurringFrequencyType} />
            {blockScheduleFormModel.repeatSchedule && (
              <FieldV2 fieldProps={schema.StartDate} />
            )}
            <FieldV2 fieldProps={schema.EndDate} />
          </div>
          <FormFieldErrorContainer
            errorMessages={[
              ...get(errors, "recurringFrequencyType", []),
              ...get(errors, "startDate", []),
            ]}
          />
          <div className="recurrence-section_ordinal">
            <FieldV2 fieldProps={schema.RecurringOrdinalDays_AsList} />
          </div>
          <div className="recurrence-section_days">
            <FieldV2 fieldProps={schema.RecurringDays_AsList} />
            {blockScheduleFormModel.repeatSchedule &&
              blockScheduleFormModel.recurringFrequencyType ===
                RecurringFrequencyTypeOptions.Monthly && (
                <FieldLabel labelName="of each month" />
              )}
          </div>
          <FormFieldErrorContainer
            errorMessages={[
              ...get(errors, "recurringOrdinalDays_AsList", []),
              ...get(errors, "recurringDays_AsList", []),
            ]}
          />
        </div>
      </FieldFormV2>
    </div>
  );
};
