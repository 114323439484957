import React from "react";
import RelativityCard from "../Card/RelativityCard";
import { BaseField } from "../Forms/BaseField/BaseField";
import { fiaRequestFormSchema } from "../Forms/FIARequestForm/FIARequestForm";
import { FIARequest, FIARequestUpdateDTO } from "./configurations/types";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";
import { getObjectValueClassName } from "../../utils/miscellaneous";
import { Tag } from "@blueprintjs/core";

export interface FIARequestInfoProps {
  fiaRequest: FIARequest;
  numberOfColumns?: number;
  showStatusTag: boolean;
}

export const FIARequestInfo: React.FC<FIARequestInfoProps> = ({
  fiaRequest,
  numberOfColumns,
  showStatusTag,
}) => {
  return (
    <section className="fia-request-info-container">
      <RelativityCard
        headerContent={
          <div className="fia-request-info-header">
            <div>Request Info</div>
            {showStatusTag &&
              fiaRequest &&
              (fiaRequest as FIARequestUpdateDTO).requestStatus_AsString && (
                <Tag
                  className={`fia-request-status fia-request-status__${getObjectValueClassName(
                    (fiaRequest as FIARequestUpdateDTO).requestStatus_AsString
                  )}`}
                  round={true}
                >
                  {(fiaRequest as FIARequestUpdateDTO).requestStatus_AsString}
                </Tag>
              )}
          </div>
        }
        columns={numberOfColumns ?? 4}
      >
        <BaseField
          {...fiaRequestFormSchema.requestNumber_AsString(fiaRequest)}
        />
        <BaseField {...fiaRequestFormSchema.submittedDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.approvedDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.createdDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.receivedDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.canceledDate(fiaRequest)} />
        {featureToggleStore.featureToggles?.EnableFiaFollowUpReason && (
          <BaseField
            {...fiaRequestFormSchema.followUpReason_AsString(fiaRequest)}
          />
        )}
      </RelativityCard>
    </section>
  );
};
