import { useEffect, useState } from "react";
import {
  FIARequest,
  FIARequestUpdateDTO,
} from "../FIARequests/configurations/types";
import { fiaRequestApiClient } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { AllFIARequestODataReadDTO } from "./types";
import { validateFIARequestRequirements } from "../FIARequests/UseFIARequestFlyout";

const useFiaFilingFormCard = (
  fiaRequestResult: AllFIARequestODataReadDTO | undefined
) => {
  const [fiaRequest, setFiaRequest] = useState<
    FIARequest | FIARequestUpdateDTO | undefined
  >(undefined);
  const [isDocumentRenameActive, setIsDocumentRenameActive] = useState(false);
  const [documentErrors, setDocumentErrors] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    if (fiaRequestResult?.applicationId && fiaRequestResult?.id) {
      fiaRequestApiClient
        .getRequest(fiaRequestResult.applicationId, fiaRequestResult.id)
        .then((fiaRequest) => {
          setFiaRequest(fiaRequest);
          validateFIARequestRequirements(
            fiaRequestResult!.applicationId,
            fiaRequestResult!.id,
            fiaRequest,
            setErrors,
            setDocumentErrors
          );
        });
    }

    return () => {
      setFiaRequest(undefined);
      setErrors({});
      setDocumentErrors([]);
    };
  }, [fiaRequestResult?.applicationId, fiaRequestResult?.id]);

  return {
    fiaRequest,
    setFiaRequest,
    isDocumentRenameActive,
    setIsDocumentRenameActive,
    documentErrors,
    setDocumentErrors,
    errors,
    setErrors,
  };
};

export default useFiaFilingFormCard;
