import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import "./styles.scss";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { documentFilingAIClient } from "../../lib/apiClients/documentFilingAI/documentFilingAIClient";
import { debounce } from "lodash";

interface PageSidebarDocumentFilingProps {
  onOpenChanged?: (isOpen: boolean) => void;
  disabled?: boolean;
}
export const PageSidebarDocumentFiling: React.FC<
  PageSidebarDocumentFilingProps
> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const [documentFilingQueueCount, setDocumentFilingQueueCount] =
    React.useState<number>();

  // Ensure it doesn't get recreated on every render
  const init = useCallback(
    debounce(async () => {
      updateDocumentFilingQueueCount();
      setIsReady(true);
    }, 1000),
    []
  );

  useEffect(() => {
    init();

    return () => {
      init.cancel();
    };
  }, [init]);

  const prevIsOpenRef = useRef<boolean>();

  useEffect(() => {
    if (
      !isReady ||
      (prevIsOpenRef.current !== undefined && prevIsOpenRef.current === isOpen)
    ) {
      return;
    }

    prevIsOpenRef.current = isOpen;

    if (!isOpen) {
      updateDocumentFilingQueueCount();
    }
  }, [isOpen]);

  const sidebarButtonClickHandler = () => {
    dialogsViewerStore.setIsDocumentFilingDialogOpen(true, {
      onDocumentDeleted: async () => {
        await updateDocumentFilingQueueCount();
      },
      onDocumentFiled: async () => {
        updateDocumentFilingQueueCount();
      },
      onDocumentUploaded: async () => {
        await updateDocumentFilingQueueCount();
      },
    });
    setIsOpen(!isOpen);
  };

  const getDocumentFilingQueueCount = async () => {
    const count = await documentFilingAIClient.getDocumentFilingQueueCount();
    return count;
  };

  const updateDocumentFilingQueueCount = async () => {
    const count = await getDocumentFilingQueueCount();
    setDocumentFilingQueueCount(count);
  };

  // Rendering on fn since render inside Button component is having rendering issues
  const renderCountBadge = () => {
    if (documentFilingQueueCount && documentFilingQueueCount > 0) {
      return (
        <span className="minimize-badge e-badge e-badge-primary">
          {documentFilingQueueCount}
        </span>
      );
    }
  };

  return (
    <div
      className={classnames("page-sidebar-item", {
        "page-sidebar-item__selected": isOpen,
        "page-sidebar-item__attention": true,
      })}
    >
      <Button
        minimal={true}
        intent={Intent.PRIMARY}
        onClick={sidebarButtonClickHandler}
        disabled={props.disabled}
      >
        <Icon icon={IconNames.UPLOAD} size={20} />
        <>{renderCountBadge()}</>
      </Button>
    </div>
  );
};
