import { schemaStore } from "../../../stores/SchemaStore";
import { LegalInformation } from "../../../types/Application/LegalInformation";
import {
  AllFields,
  SectionHeaderProps,
  Option,
} from "../../Fields/types/fieldTypes";
import {
  getAttorneyAsyncAutocompleteFieldProps,
  getSpousePowerOfAttorneyContactAsyncAutocompleteFieldProps,
  FormSchemaBuilder,
  getResidentGuardianContactAsyncAutocompleteFieldProps,
  getResidentPowerOfAttorneyContactAsyncAutocompleteFieldProps,
} from "../types";
import { get } from "lodash";
import { Application } from "../../../types/Application";
import { IconNames } from "@blueprintjs/icons";
import { notInListValue } from "../../Fields/AsyncAutocompleteField/AsyncAutocompleteODataV4Adaptor";
import { applicationStore } from "../../../stores/ApplicationStore";
import { userStore } from "../../../stores/UserStore";

export const newLegalInformation: LegalInformation = {
  //Authority
  doesResidentHaveGuardianship: undefined,
  residentGuardianContactId: undefined,
  doesResidentHavePowerOfAttorney: undefined,
  residentPowerOfAttorneyContactId: undefined,
  residentAttorneyPreparingPOA: undefined,
  authorityDate: undefined,
  authorityPOADocumentDate: undefined,
  residentAttorneyReferenceNumber: undefined,

  //Spouse POA
  doesSpouseHavePowerOfAttorney: undefined,
  spousePowerOfAttorneyContactId: undefined,
  spousePOADocumentDate: undefined,
  spouseAttorneyPreparingPOA: undefined,
  spouseAttorneyReferenceNumber: undefined,

  //Legal
  isAttorneyContacted: undefined,
  attorneyId: undefined,
  hasOtherAttorney: undefined,
  otherAttorneyName: undefined,
  otherAttorneyEmail: undefined,
  otherAttorneyPhoneNumber: undefined,
  attorneyContactDate: undefined,
  hasAttorneyBeenEngaged: undefined,
  attorneyFee: undefined,
  attorneyNotes: undefined,
  hasPersonalServiceContractBeenPerformed: undefined,
  contractWith: undefined,
  pscFairMarketValue: undefined,
  attorneyPreparingPSC: undefined,
  attorneyPreparingQIT: undefined,
  isQITExecuted: undefined,
  isQITFundedProperly: undefined,
  qitExecutedDate: undefined,
  qitFundingDate: undefined,
  attorneyEmail: undefined,
  attorneyPhoneNumber: undefined,
  pscDate: undefined,
  isPSCAccepted: undefined,
  attorneyReferenceNumberPSC: undefined,
  attorneyReferenceNumberQIT: undefined,
  qitAmountFunded: undefined,

  //Contract & Fee Details
  isMDROverAssetsCase: undefined,
  overAssetsFee: undefined,
  isPrivatePayCase: undefined,
  privatePayFee: undefined,
};

export const legalInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId,
  onFormSubmit,
  _disableAllFields,
  hideSectionHeader
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const legalInformation = form as LegalInformation;
  const application = form as Application;

  return [
    {
      type: "Section",
      label: "Legal Information",
      hidden: !!hideSectionHeader,
    } as SectionHeaderProps,
    {
      type: "Section",
      label: "Authority",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentHaveGuardianship"
      ),
      value: String(legalInformation.doesResidentHaveGuardianship),
      onSubmit: (doesResidentHaveGuardianship) => {
        onValueChange({
          ...legalInformation,
          residentGuardianContactId: undefined,
          authorityDate: "",
          doesResidentHaveGuardianship: Number(doesResidentHaveGuardianship),
        });
      },
      errorMessages: get(errors, "doesResidentHaveGuardianship"),
      isRequired: true,
    } as AllFields,

    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "residentGuardianContactId"
      ),
      ...getResidentGuardianContactAsyncAutocompleteFieldProps(application?.id),
      errorMessages: get(errors, "residentGuardianContactId"),
      width: "45%",
      isRequired: true,
      hidden: !(legalInformation.doesResidentHaveGuardianship === 1),
      value: legalInformation.residentGuardianContactId,
      onSubmit: (contactOptions: Option) => {
        const newField: Partial<LegalInformation> = {
          residentGuardianContactId: contactOptions?.value,
        };
        onValueChange(newField);
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "authorityDate"),
      value: legalInformation.authorityDate,
      hidden: !(legalInformation.doesResidentHaveGuardianship === 1),
      width: "45%",
      onSubmit: (authorityDate) => {
        onValueChange({ authorityDate });
      },
      maxDate: new Date(),
      isRequired: true,
      errorMessages: get(errors, "authorityDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentHavePowerOfAttorney"
      ),
      onSubmit: (doesResidentHavePowerOfAttorney) => {
        onValueChange({
          ...legalInformation,
          residentPowerOfAttorneyContactId: undefined,
          authorityPOADocumentDate: "",
          doesResidentHavePowerOfAttorney: Number(
            doesResidentHavePowerOfAttorney
          ),
        });
      },
      value: String(legalInformation.doesResidentHavePowerOfAttorney),
      isRequired: true,
      errorMessages: get(errors, "doesResidentHavePowerOfAttorney"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "residentPowerOfAttorneyContactId"
      ),
      ...getResidentPowerOfAttorneyContactAsyncAutocompleteFieldProps(
        application?.id
      ),
      errorMessages: get(errors, "residentPowerOfAttorneyContactId"),
      width: "45%",
      isRequired: true,
      hidden: !(legalInformation.doesResidentHavePowerOfAttorney === 1),
      value: legalInformation.residentPowerOfAttorneyContactId,
      onSubmit: (contactOptions: Option) => {
        const newField: Partial<LegalInformation> = {
          residentPowerOfAttorneyContactId: contactOptions?.value,
        };
        onValueChange(newField);
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "authorityPOADocumentDate"
      ),
      value: legalInformation.authorityPOADocumentDate,
      hidden: !(legalInformation.doesResidentHavePowerOfAttorney === 1),
      onSubmit: (authorityPOADocumentDate) => {
        onValueChange({ authorityPOADocumentDate });
      },
      width: "45%",
      isRequired: true,
      maxDate: new Date(),
      errorMessages: get(errors, "authorityPOADocumentDate"),
    } as AllFields,
    // Comment out as requested
    // {
    //   ...schemaStore.getFieldSchemaByName(
    //     modelName,
    //     "residentAttorneyPreparingPOA"
    //   ),
    //   value: legalInformation.residentAttorneyPreparingPOA,
    //   hidden: !(legalInformation.doesResidentHavePowerOfAttorney === 3),
    //   onSubmit: (residentAttorneyPreparingPOA) => {
    //     onValueChange({ residentAttorneyPreparingPOA });
    //   },
    //   isRequired: true,
    //   width: "45%",
    //   errorMessages: get(errors, "residentAttorneyPreparingPOA"),
    // } as AllFields,
    //Spouse Fields
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesSpouseHavePowerOfAttorney"
      ),
      value: String(legalInformation.doesSpouseHavePowerOfAttorney),
      onSubmit: (doesSpouseHavePowerOfAttorney) => {
        onValueChange({
          doesSpouseHavePowerOfAttorney: Number(doesSpouseHavePowerOfAttorney),
        });
      },
      hidden: !applicationStore.application?.isMarried,
      isRequired: true,
      errorMessages: get(errors, "doesSpouseHavePowerOfAttorney"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spousePowerOfAttorneyContactId"
      ),
      ...getSpousePowerOfAttorneyContactAsyncAutocompleteFieldProps(
        application?.id,
        application?.spouse?.id
      ),
      errorMessages: get(errors, "spousePowerOfAttorneyContactId"),
      width: "45%",
      isRequired: true,
      hidden: !(
        applicationStore.application?.isMarried &&
        legalInformation.doesSpouseHavePowerOfAttorney === 1
      ),
      value: legalInformation.spousePowerOfAttorneyContactId,
      onSubmit: (contactOptions: Option) => {
        const newField: Partial<LegalInformation> = {
          spousePowerOfAttorneyContactId: contactOptions?.value,
        };
        onValueChange(newField);
      },
    } as AllFields,

    //contactodata
    {
      ...schemaStore.getFieldSchemaByName(modelName, "spousePOADocumentDate"),
      value: legalInformation.spousePOADocumentDate,
      hidden: !(
        applicationStore.application?.isMarried &&
        legalInformation.doesSpouseHavePowerOfAttorney === 1
      ),
      onSubmit: (spousePOADocumentDate) => {
        onValueChange({ spousePOADocumentDate });
      },
      isRequired: true,
      width: "45%",
      maxDate: new Date(),
      errorMessages: get(errors, "spousePOADocumentDate"),
    } as AllFields,
    // Comment out as requested
    // {
    //   ...schemaStore.getFieldSchemaByName(
    //     modelName,
    //     "spouseAttorneyPreparingPOA"
    //   ),
    //   hidden: !(legalInformation.doesSpouseHavePowerOfAttorney === 3),
    //   value: legalInformation.spouseAttorneyPreparingPOA,
    //   onSubmit: (spouseAttorneyPreparingPOA) => {
    //     onValueChange({ spouseAttorneyPreparingPOA });
    //   },
    //   width: "45%",
    //   isRequired: true,
    //   errorMessages: get(errors, "spouseAttorneyPreparingPOA"),
    // } as AllFields,
    {
      type: "Section",
      label: "Legal",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isAttorneyContacted"),
      value: String(legalInformation.isAttorneyContacted),
      onSubmit: (isAttorneyContacted) => {
        onValueChange({
          ...legalInformation,
          attorneyId: undefined,
          attorneyPhoneNumber: null,
          attorneyContactDate: null,
          hasAttorneyBeenEngaged: null,
          attorneyFee: null,
          attorneyNotes: null,
          otherAttorneyName: null,
          otherAttorneyEmail: null,
          otherAttorneyPhoneNumber: null,
          isAttorneyContacted: Number(isAttorneyContacted),
        });
      },
      isRequired: true,
      errorMessages: get(errors, "isAttorneyContacted"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "attorneyId"),
      ...getAttorneyAsyncAutocompleteFieldProps(),
      hasNotInListOption: true,
      errorMessages: get(errors, "attorneyId"),
      width: "45%",
      isRequired: true,
      hidden: !(legalInformation.isAttorneyContacted == 1),
      value: legalInformation.hasOtherAttorney
        ? notInListValue
        : legalInformation.attorneyId,
      onSubmit: (
        attorneyOptions: Option & { phoneNumber: string; email: string }
      ) => {
        const hasOtherAttorney = attorneyOptions?.value === notInListValue;
        if (hasOtherAttorney) {
          onValueChange({
            attorneyId: undefined,
            hasOtherAttorney: true,
          });
        } else {
          const newField: Partial<LegalInformation> = {
            attorneyId: attorneyOptions?.value,
            attorneyPhoneNumber: attorneyOptions?.phoneNumber,
            attorneyEmail: attorneyOptions?.email,
            hasOtherAttorney: false,
          };
          onValueChange(newField);
        }
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "attorneyContactDate"),
      value: legalInformation.attorneyContactDate,
      hidden: !(legalInformation.isAttorneyContacted == 1),
      width: "45%",
      onSubmit: (attorneyContactDate) => {
        onValueChange({ attorneyContactDate });
      },
      isRequired: true,
      maxDate: new Date(),
      errorMessages: get(errors, "attorneyContactDate"),
    } as AllFields,
    {
      label: "Attorney Email",
      value: legalInformation.attorneyEmail,
      hidden: !(
        !legalInformation.hasOtherAttorney &&
        legalInformation.attorneyId &&
        legalInformation.isAttorneyContacted == 1
      ),
      formId: formId,
      width: "45%",
      type: "Text",
      disabled: true,
      leftIcon: IconNames.Envelope,
    } as AllFields,
    {
      label: "Attorney Phone Number",
      value: legalInformation.attorneyPhoneNumber,
      hidden: !(
        !legalInformation.hasOtherAttorney &&
        legalInformation.attorneyId &&
        legalInformation.isAttorneyContacted == 1
      ),
      width: "45%",
      type: "Text",
      disabled: true,
      leftIcon: IconNames.Phone,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "otherAttorneyName"),
      value: legalInformation.otherAttorneyName,
      hidden: !(
        legalInformation.hasOtherAttorney &&
        legalInformation.isAttorneyContacted == 1
      ),
      onSubmit: (otherAttorneyName) => {
        onValueChange({ otherAttorneyName });
      },
      isRequired: true,
      width: "45%",
      errorMessages: get(errors, "otherAttorneyName"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "otherAttorneyEmail"),
      value: legalInformation.otherAttorneyEmail,
      hidden: !(
        legalInformation.hasOtherAttorney &&
        legalInformation.isAttorneyContacted == 1
      ),
      onSubmit: (otherAttorneyEmail) => {
        onValueChange({ otherAttorneyEmail });
      },
      isRequired: true,
      width: "45%",
      errorMessages: get(errors, "otherAttorneyEmail"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "otherAttorneyPhoneNumber"
      ),
      value: legalInformation.otherAttorneyPhoneNumber,
      hidden: !(
        legalInformation.hasOtherAttorney &&
        legalInformation.isAttorneyContacted == 1
      ),
      onSubmit: (otherAttorneyPhoneNumber) => {
        onValueChange({ otherAttorneyPhoneNumber });
      },
      isRequired: true,
      width: "45%",
      errorMessages: get(errors, "otherAttorneyPhoneNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasAttorneyBeenEngaged"),
      value: legalInformation.hasAttorneyBeenEngaged,
      hidden: !(legalInformation.isAttorneyContacted == 1),
      width: "56%",
      onSubmit: (hasAttorneyBeenEngaged) => {
        onValueChange({ hasAttorneyBeenEngaged });
      },
      isRequired: true,
      errorMessages: get(errors, "hasAttorneyBeenEngaged"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "attorneyFee"),
      value: legalInformation.attorneyFee,
      hidden: !legalInformation.hasAttorneyBeenEngaged,
      width: "45%",
      onSubmit: (attorneyFee) => {
        onValueChange({ attorneyFee });
      },
      isRequired: true,
      errorMessages: get(errors, "attorneyFee"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "attorneyNotes"),
      value: legalInformation.attorneyNotes,
      hidden: !(legalInformation.isAttorneyContacted == 1),
      onSubmit: (attorneyNotes) => {
        onValueChange({ attorneyNotes });
      },
      isRequired: true,
      errorMessages: get(errors, "attorneyNotes"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasPersonalServiceContractBeenPerformed"
      ),
      isRequired: true,
      value: String(legalInformation.hasPersonalServiceContractBeenPerformed),
      onSubmit: (hasPersonalServiceContractBeenPerformed) => {
        onValueChange({
          ...legalInformation,
          contractWith: "",
          pscFairMarketValue: "",
          pscDate: "",
          isPSCAccepted: "",
          hasPersonalServiceContractBeenPerformed: Number(
            hasPersonalServiceContractBeenPerformed
          ),
        });
      },
      errorMessages: get(errors, "hasPersonalServiceContractBeenPerformed"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "contractWith"),
      value: legalInformation.contractWith,
      hidden: !(legalInformation.hasPersonalServiceContractBeenPerformed === 1),
      onSubmit: (contractWith) => {
        onValueChange({ contractWith });
      },
      width: "45%",
      isRequired: true,
      errorMessages: get(errors, "contractWith"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "pscFairMarketValue"),
      value: legalInformation.pscFairMarketValue,
      hidden: !(legalInformation.hasPersonalServiceContractBeenPerformed === 1),
      onSubmit: (pscFairMarketValue) => {
        onValueChange({ pscFairMarketValue });
      },
      width: "45%",
      isRequired: true,
      errorMessages: get(errors, "pscFairMarketValue"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "pscDate"),
      value: legalInformation.pscDate,
      hidden: !(legalInformation.hasPersonalServiceContractBeenPerformed === 1),
      onSubmit: (pscDate) => {
        onValueChange({ pscDate });
      },
      width: "45%",
      isRequired: true,
      maxDate: new Date(),
      errorMessages: get(errors, "pscDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isPSCAccepted"),
      value: legalInformation.isPSCAccepted,
      hidden: !(legalInformation.hasPersonalServiceContractBeenPerformed === 1),
      onSubmit: (isPSCAccepted) => {
        onValueChange({ isPSCAccepted });
      },
      width: "45%",
      isRequired: true,
      errorMessages: get(errors, "isPSCAccepted"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "attorneyPreparingPSC"),
      value: legalInformation.attorneyPreparingPSC,
      hidden: !(legalInformation.hasPersonalServiceContractBeenPerformed === 3),
      onSubmit: (attorneyPreparingPSC) => {
        onValueChange({ attorneyPreparingPSC });
      },
      isRequired: true,
      width: "45%",
      errorMessages: get(errors, "attorneyPreparingPSC"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isQITExecuted"),
      value: String(legalInformation.isQITExecuted),
      onSubmit: (isQITExecuted) => {
        onValueChange({
          ...legalInformation,
          isQITFundedProperly: undefined,
          qitExecutedDate: "",
          qitFundingDate: "",
          qitAmountFunded: "",
          isQITExecuted: Number(isQITExecuted),
        });
      },
      isRequired: true,
      width: "45%",
      errorMessages: get(errors, "isQITExecuted"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isQITFundedProperly"),
      value: legalInformation.isQITFundedProperly,
      onSubmit: (isQITFundedProperly) => {
        onValueChange({ isQITFundedProperly });
      },
      isRequired: true,
      hidden: !(legalInformation.isQITExecuted === 1),

      width: "45%",
      errorMessages: get(errors, "isQITFundedProperly"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "qitExecutedDate"),
      value: legalInformation.qitExecutedDate,
      hidden: !(legalInformation.isQITExecuted === 1),
      width: "25%",
      onSubmit: (qitExecutedDate) => {
        onValueChange({ qitExecutedDate });
      },
      isRequired: true,
      maxDate: new Date(),
      errorMessages: get(errors, "qitExecutedDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "qitFundingDate"),
      value: legalInformation.qitFundingDate,
      hidden: !(legalInformation.isQITExecuted === 1),
      width: "25%",
      onSubmit: (qitFundingDate) => {
        onValueChange({ qitFundingDate });
      },
      isRequired: true,
      errorMessages: get(errors, "qitFundingDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "qitAmountFunded"),
      value: legalInformation.qitAmountFunded,
      hidden: !(legalInformation.isQITExecuted === 1),
      width: "25%",
      onSubmit: (qitAmountFunded) => {
        onValueChange({ qitAmountFunded });
      },
      isRequired: true,
      errorMessages: get(errors, "qitAmountFunded"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "attorneyPreparingQIT"),
      value: legalInformation.attorneyPreparingQIT,
      hidden: !(legalInformation.isQITExecuted === 3),
      onSubmit: (attorneyPreparingQIT) => {
        onValueChange({ attorneyPreparingQIT });
      },
      isRequired: true,
      width: "45%",
      errorMessages: get(errors, "attorneyPreparingQIT"),
    } as AllFields,
    {
      type: "Section",
      label: "Contract & Fees",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isMDROverAssetsCase"),
      value: legalInformation.isMDROverAssetsCase,
      onSubmit: (isMDROverAssetsCase) => {
        onValueChange({ isMDROverAssetsCase });
        if (onFormSubmit) {
          onFormSubmit({ ...legalInformation, isMDROverAssetsCase });
        }
      },
      width: "30%",
      disabled: !userStore.user?.canEditOverAssetPrivatePayFields,
      isRequired: true,
      errorMessages: get(errors, "isMDROverAssetsCase"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "overAssetsFee"),
      value: legalInformation.overAssetsFee,
      hidden: !legalInformation.isMDROverAssetsCase,
      onSubmit: (overAssetsFee) => {
        onValueChange({ overAssetsFee });
      },
      width: "65%",
      disabled: true,
      isRequired: true,
      errorMessages: get(errors, "overAssetsFee"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isPrivatePayCase"),
      value: legalInformation.isPrivatePayCase,
      hidden: !(legalInformation.isMDROverAssetsCase === false),
      onSubmit: (isPrivatePayCase) => {
        onValueChange({ isPrivatePayCase });
        if (onFormSubmit) {
          onFormSubmit({ ...legalInformation, isPrivatePayCase });
        }
      },
      width: "30%",
      disabled: !userStore.user?.canEditOverAssetPrivatePayFields,
      isRequired: true,
      errorMessages: get(errors, "isPrivatePayCase"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "privatePayFee"),
      value: legalInformation.privatePayFee,
      hidden: !legalInformation.isPrivatePayCase,
      onSubmit: (privatePayFee) => {
        onValueChange({ privatePayFee });
      },
      width: "65%",
      disabled: true,
      isRequired: true,
      errorMessages: get(errors, "privatePayFee"),
    } as AllFields,
  ];
};
