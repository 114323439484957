import { useEffect, useState } from "react";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { errorStore, genericErrorMessage } from "../../stores/ErrorStore";
import {
  SignatureHolderReadDTO,
  SignatureTypes,
  SignaturesValidationDetailsDTO,
} from "../../types/Application/Signature";
import hash from "object-hash";
import { throws } from "assert";

export const useSignature = (applicationId?: string) => {
  const [ready, setReady] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [signatures, setSignatures] = useState<SignatureHolderReadDTO[]>([]);
  const [signaturesHash, setSignaturesHash] = useState<string>();
  const [signaturesValidationDetails, setSignaturesValidationDetails] =
    useState<SignaturesValidationDetailsDTO>();

  useEffect(() => {
    (async () => {
      if (ready && signaturesHash !== hash(signatures)) {
        const details = await fetchApplicationSignaturesValidationDetails(
          signatures
        );
        setSignaturesHash(hash(signatures));
        setSignatureValues(signatures, details);
      }
    })();
  }, [signatures]);

  const fetchSignatures = async () => {
    if (!applicationId) {
      return;
    }

    setReady(false);
    // fetch

    const fetchedSignatures = await applicationApiClient
      .getApplicationSignatures(applicationId)
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });

    // set
    if (fetchedSignatures) {
      const details = await fetchApplicationSignaturesValidationDetails(
        fetchedSignatures
      );
      setSignatureValues(fetchedSignatures, details);
    }

    setReady(true);
  };

  const fetchApplicationSignaturesValidationDetails = async (
    appSignatures: SignatureHolderReadDTO[]
  ) => {
    if (!applicationId) {
      return;
    }

    // fetch
    const fetchedSignatureDetails = await applicationApiClient
      .getApplicationSignaturesValidationDetails(applicationId, appSignatures)
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });

    if (!fetchedSignatureDetails) {
      return;
    }
    // set
    setSignaturesValidationDetails(fetchedSignatureDetails);

    return fetchedSignatureDetails;
  };

  const setSignatureValues = (
    signatures: SignatureHolderReadDTO[],
    signaturesValidationDetailsValue?: SignaturesValidationDetailsDTO
  ) => {
    if (!signaturesValidationDetailsValue) {
      signaturesValidationDetailsValue = signaturesValidationDetails;
    }
    if (!signaturesValidationDetailsValue) {
      throws(() => "Error. signaturesValidationDetailsValue is undefined");
      return;
    }

    const signaturesWithIsRequired = setSignaturesWithIsRequired2(
      signatures,
      signaturesValidationDetailsValue
    );
    setSignatures(signaturesWithIsRequired);
  };

  const setSignaturesWithIsRequired2 = (
    signatures: SignatureHolderReadDTO[],
    signaturesValidationDetails: SignaturesValidationDetailsDTO
  ) => {
    const signaturesWithIsRequired = signatures.map((signature) => {
      return {
        ...signature,
        isRequired: signature.types?.includes(SignatureTypes.Resident)
          ? signaturesValidationDetails.requiredSignaturesData?.some((x) =>
              x.types?.includes(SignatureTypes.Resident)
            )
          : signaturesValidationDetails.requiredSignaturesData?.some(
              (x) => x.contactId === signature.contactId
            ),
        isDisabled: signature.types?.includes(SignatureTypes.Resident)
          ? signaturesValidationDetails.residentGovermentIdRequirePOAGuardian
          : signature.types?.includes(SignatureTypes.Spouse)
          ? signaturesValidationDetails.spouseGovermentIdRequirePOA
          : false,
      };
    });
    return signaturesWithIsRequired;
  };

  const handleSignatureFormSubmit = async (
    currentSignature: SignatureHolderReadDTO,
    incomingSignature: SignatureHolderReadDTO
  ) => {
    const newSignatures = signatures.map((ogSignature) =>
      ogSignature.signatureHolderName === currentSignature.signatureHolderName
        ? { ...currentSignature, ...incomingSignature }
        : ogSignature
    );
    setSignatureValues(newSignatures);

    if (incomingSignature.signatureBase64 !== undefined) {
      setIsDirty(true);
    }
  };

  return {
    ready,
    isSaving,
    setIsSaving,
    isDirty,
    setIsDirty,
    signatures,
    signaturesValidationDetails,
    fetchSignatures,
    handleSignatureFormSubmit,
  };
};
