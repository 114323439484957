import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { ApplicationChild } from "../../../types/Application/ApplicationChild";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getFacilityAsyncAutocompleteFieldProps,
  getProcessorAsyncAutocompleteFieldProps,
} from "../types";

export const newApplicationChild: ApplicationChild = {
  productType: undefined,
  processorId: undefined,
  medicaidNeededDate: undefined,
  facilityId: undefined,
};

export const applicationChildFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "application";
  const applicationChild = form as ApplicationChild;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityId"),
      ...getFacilityAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      errorMessages: get(errors, "facilityId"),
      width: "45%",
      isRequired: true,
      value: applicationChild.facilityId,
      onSubmit: (facilityOptions: Option & { area_AsString: string }) => {
        onValueChange({
          facilityId: facilityOptions?.value,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        "childApplicationCreateDTO",
        "productType"
      ),
      onSubmit: (option: Option) => {
        onValueChange({
          productType: option.value,
          productType_AsString: option.label,
        });
      },
      width: "45%",
      errorMessages: get(errors, "productType"),
      value: applicationChild.productType,
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "processorId"),
      ...getProcessorAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      isRequired: true,
      width: "45%",
      value: applicationChild.processorId,
      errorMessages: get(errors, "processorId"),
      onSubmit: (processorOptions: Option) => {
        onValueChange({
          processorId: processorOptions?.value,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "medicaidNeededDate"),
      errorMessages: get(errors, "medicaidNeededDate"),
      onSubmit: (medicaidNeededDate) => {
        onValueChange({ medicaidNeededDate });
      },
      value: applicationChild.medicaidNeededDate,
      width: "45%",
      isRequired: true,
    } as AllFields,
  ];
};
