import { GridColumn } from "@ucl/library";

export const documentFilingQueueGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "document_FileName",
    headerText: "Document Name",
    type: "string",
    width: 300,
  }),
  new GridColumn({
    field: "document_UploadedAt_AsDateOnly",
    headerText: "Uploaded",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
    width: 130,
  }),
  new GridColumn({
    field: "queueStatus_AsString",
    headerText: "Status",
    type: "string",
    width: 130,
  }),
  new GridColumn({
    field: "matchFound",
    headerText: "Match Found?",
    type: "boolean",
    width: 200,
  }),
];
