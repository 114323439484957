import { Dialog } from "@blueprintjs/core";
import { BaseDialogProps } from "../Dialogs/types/types";
import { DocumentFilingComponent } from "./DocumentFilingComponent";
import { DocumentFilingEvents } from "./useDocumentFiling";

export interface DocumentFilingDialogProps extends BaseDialogProps {
  checklistItemId?: string;
  documentId?: string;
  events?: DocumentFilingEvents;
}

export const DocumentFilingDialog: React.FC<DocumentFilingDialogProps> = (
  props
) => {
  return (
    <Dialog
      portalClassName="mdr-dialog fullscreen-dialog"
      className="document-filing-dialog"
      isOpen={props.isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Filing AI"}
      onClose={props.closeDialog}
    >
      <DocumentFilingComponent {...props.events} onCancel={props.closeDialog} />
    </Dialog>
  );
};
