import { useEffect, useState } from "react";
import {
  ApplicationFilingODataReadDTO,
  DocumentFilingAssociatedChecklistItem,
  DocumentFilingQueueODataReadDTO,
  StateNoticeFilingProcessDocumentResult,
} from "./types";
import { UseDocumentFilingProps } from "./useDocumentFiling";
import variables from "../../config/variables";
import { getAuthRequestHeader } from "../../lib/apiClients/baseApiClient";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { AppToaster } from "../Toast/Toast";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { Intent } from "@blueprintjs/core";
import { AsyncAutoCompleteFieldProps } from "@ucl/library/lib/components/Fields/DropdownFields/AsyncAutoCompleteField/AsyncAutoCompleteField";
import { checklistApiClient } from "../../lib/apiClients/checklist/checklistApiClient";
import { startCase, toLower } from "lodash";
import { ExceptionDetails } from "../../lib/exceptions/types";
const useStateNoticeDocumentFiling = (props: UseDocumentFilingProps) => {
  const [isUploadingStateNoticeDoc, setIsUploadingStateNoticeDoc] =
    useState(false);
  const [application, setApplication] =
    useState<ApplicationFilingODataReadDTO>();
  const [
    stateNoticeFilingProcessDocumentResult,
    setStateNoticeFilingProcessDocumentResult,
  ] = useState<StateNoticeFilingProcessDocumentResult>();

  const stateNoticeDocumentType = 12005;
  const dvtUploadsDocumentType = 156;

  useEffect(() => {
    if (application?.id && props.associatedChecklistItem?.id) {
      return;
    }

    if (application?.id) {
      fetchStateNoticeChecklistItem(application);
      props.setApplicationId(application.id);
    } else {
      props.setAssociatedChecklistItem(undefined);
      props.setApplicationId(undefined);
    }

    props.setNewDocumentName("State Notice.pdf");
  }, [application]);

  const stateNoticeSearchAsyncAutoCompleteFieldProps: AsyncAutoCompleteFieldProps =
    {
      label: "Search",
      odataUrl: `${variables.apiBaseUrl}odata/applicationfilingsearchodata`,
      getAuthRequestHeader: getAuthRequestHeader,
      fieldNames: [
        "id",
        "caseNumber",
        "residentFullName",
        "fileNumber",
        "facilityState_AsString",
      ],
      autoCompleteSettings: {
        fields: { text: "residentFullName", value: "id" },
        query: new Query()
          .select([
            "id",
            "caseNumber",
            "residentFullName",
            "facilityState_AsString",
            "fileNumber",
          ])
          .sortBy("residentFullName")
          .take(50),
        headerTemplate:
          "<div class='fiafiling-content-form-search-results-header state-notice'><span>File Number</span><span>Resident Name</span><span>State</span><span>Case Number</span></div>",
        itemTemplate:
          "<div class='fiafiling-content-form-search-results-content state-notice'><span>${fileNumber}</span><span>${residentFullName}</span><span>${facilityState_AsString}</span><span>${caseNumber}</span></div>",
      },
      sortBy: "residentFullName",
      searchBy: (searchText: string) =>
        new Predicate(
          new Predicate("caseNumber", "contains", searchText),
          "or",
          [
            new Predicate("fileNumber", "contains", searchText),
            new Predicate("residentFullName", "contains", searchText),
          ]
        ),
      onSubmit: (value) => {
        if (!value) {
          return;
        }
        setApplication({
          ...(value as unknown as ApplicationFilingODataReadDTO),
        });
      },
      submitAllAssigments: true,
    };

  const initStateNoticeDocument = (
    stateNoticeFilingProcessDocument: StateNoticeFilingProcessDocumentResult
  ) => {
    if (stateNoticeFilingProcessDocument?.applicationData?.id) {
      setStateNoticeFilingProcessDocumentResult({
        ...stateNoticeFilingProcessDocument,
      });
      setApplication({
        ...stateNoticeFilingProcessDocument.applicationData,
      });
    } else {
      const initSearchValue =
        stateNoticeFilingProcessDocument?.parsedDocumentData?.caseNumber || "";
      props.searchValueRef.current = startCase(toLower(initSearchValue));
      props.autoFillDataRef.current =
        stateNoticeFilingProcessDocument?.applicationResults;
      setStateNoticeFilingProcessDocumentResult({
        ...({
          applicationData: undefined,
          parsedDocumentData: undefined,
        } as StateNoticeFilingProcessDocumentResult),
      });
    }
  };

  const fetchStateNoticeChecklistItem = async (
    applicationOdata: ApplicationFilingODataReadDTO
  ) => {
    if (!applicationOdata?.id) {
      return;
    }

    const applicationChecklist = await checklistApiClient
      .getApplicationChecklist(applicationOdata.id)
      .catch(() => {
        AppToaster.show({
          message: genericErrorMessage,
          intent: Intent.DANGER,
        });
      });

    const stateNoticeChecklistItem = applicationChecklist?.find(
      (x) => x.documentType === stateNoticeDocumentType
    );
    const dvtUploadsChecklistItem = applicationChecklist?.find(
      (x) => x.documentType === dvtUploadsDocumentType
    );

    if (stateNoticeChecklistItem) {
      props.setAssociatedChecklistItem({ ...stateNoticeChecklistItem });
    } else if (dvtUploadsChecklistItem) {
      props.setAssociatedChecklistItem({ ...dvtUploadsChecklistItem });
    } else {
      props.setAssociatedChecklistItem({
        ...({
          displayName: "No associated checklist item",
        } as DocumentFilingAssociatedChecklistItem),
      });
    }
  };

  const submitStateNoticeDocument = async (): Promise<
    DocumentFilingQueueODataReadDTO | undefined
  > => {
    if (
      !props.document?.id ||
      !application?.id ||
      !props.associatedChecklistItem?.id
    ) {
      return undefined;
    }

    let result = undefined;
    await applicationApiClient
      .createStateNoticeFiling(application.id, {
        documentId: props.document.id,
        documentName: props.newDocumentName || props.document.originalFileName,
        documentVerifyDTO: props.documentVerifyDTO,
      })
      .then((stateNoticeResult: DocumentFilingQueueODataReadDTO) => {
        AppToaster.show({
          message: "State Notice submitted successfully",
          intent: Intent.SUCCESS,
          action: {
            text: "Go to Application",
            onClick: () => {
              window.open(
                `/application-page/${application.id}/checklist`,
                "_blank"
              );
            },
          },
        });

        result = stateNoticeResult?.id ? stateNoticeResult : null;
      })
      .catch((error) => {
        const exceptionDetails = error.response.data as ExceptionDetails;
        // if error code is 403, show message that the State Notice cannot be completed
        if (error?.response?.status === 403) {
          AppToaster.show({
            message: exceptionDetails.additionalInfo,
            intent: Intent.DANGER,
          });
        } else {
          AppToaster.show({
            message: genericErrorMessage,
            intent: Intent.DANGER,
          });
        }
        result = undefined;
      });

    return result;
  };

  return {
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    stateNoticeFilingProcessDocumentResult,
    setStateNoticeFilingProcessDocumentResult,
    application,
    setApplication,
    stateNoticeSearchAsyncAutoCompleteFieldProps,
    submitStateNoticeDocument,
    initStateNoticeDocument,
  };
};

export default useStateNoticeDocumentFiling;
