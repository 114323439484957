import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Document, DocumentVerifyDTO } from "../../types/Document";
import {
  AllFIARequestODataReadDTO,
  DocumentFilingAssociatedChecklistItem,
  ApplicationFilingODataReadDTO,
  DocumentFilingQueueODataReadDTO,
  StateNoticeFilingProcessDocumentResult,
} from "./types";
import { AsyncAutoCompleteFieldProps, PrimaryGridModule } from "@ucl/library";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { newDocumentVerifyDTO } from "../Checklist/Verify/useVerifyComponent";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import useFiaDocumentFiling from "./useFiaDocumentFiling";
import useStateNoticeDocumentFiling from "./useStateNoticeDocumentFiling";
import { isUndefined } from "lodash";
import useFiaFilingFormCard from "./useFiaFilingFormCard";
import { FIARequestFollowUpActionTypes } from "../FIARequests/configurations/types";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../Toast/Toast";
import { MenuEventArgs } from "@syncfusion/ej2-react-navigations";
import { documentFilingAIClient } from "../../lib/apiClients/documentFilingAI/documentFilingAIClient";
import GridRecordClickEventArgs from "../Grid/Grid/types/GridRecordClickEventArgs";

export interface DocumentFilingEvents {
  onDocumentFiled?: () => void;
  onDocumentDeleted?: () => void;
  onDocumentUploaded?: () => void;
}
export enum FilingStrategy {
  FIADocument = 1,
  StateNoticeDocument = 2,
}

export interface UseDocumentFilingProps {
  document?: Document;
  applicationId?: string;
  setApplicationId: Dispatch<SetStateAction<string | undefined>>;
  associatedChecklistItem?: DocumentFilingAssociatedChecklistItem;
  setAssociatedChecklistItem: Dispatch<
    SetStateAction<DocumentFilingAssociatedChecklistItem | undefined>
  >;
  newDocumentName?: string;
  setNewDocumentName: Dispatch<SetStateAction<string | undefined>>;
  searchValueRef: MutableRefObject<string | undefined>;
  autoFillDataRef: MutableRefObject<
    AllFIARequestODataReadDTO[] | ApplicationFilingODataReadDTO[] | undefined
  >;
  documentVerifyDTO: DocumentVerifyDTO;
  setDocumentVerifyDTO: Dispatch<SetStateAction<DocumentVerifyDTO>>;
}
const useDocumentFiling = (props: DocumentFilingEvents) => {
  const filingAIEntityId = useRef<string>(uuidv4());
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>();
  const [applicationId, setApplicationId] = useState<string>();
  const [associatedChecklistItem, setAssociatedChecklistItem] =
    useState<DocumentFilingAssociatedChecklistItem>();
  const [filingStrategy, setFilingStrategy] = useState<FilingStrategy>();
  const [documentVerifyDTO, setDocumentVerifyDTO] = useState<DocumentVerifyDTO>(
    {
      ...newDocumentVerifyDTO,
    }
  );
  const searchValueRef = useRef<string>();
  const autoFillDataRef = useRef<
    AllFIARequestODataReadDTO[] | ApplicationFilingODataReadDTO[]
  >();
  const [newDocumentName, setNewDocumentName] = useState<string>();

  const {
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    fiaRequest,
    setFiaRequest,
    fiaProcessDocumentResult,
    setFiaProcessDocumentResult,
    isFiaFilingValid,
    fiaSearchAsyncAutoCompleteFieldProps,
    submitFiaDocument,
    processFIADocument,
    fiaNeedsFollowUp,
  } = useFiaDocumentFiling({
    document,
    applicationId,
    setApplicationId,
    associatedChecklistItem,
    setAssociatedChecklistItem,
    newDocumentName,
    setNewDocumentName,
    searchValueRef,
    autoFillDataRef,
    documentVerifyDTO,
    setDocumentVerifyDTO,
  });

  const {
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    application,
    setApplication,
    stateNoticeFilingProcessDocumentResult,
    setStateNoticeFilingProcessDocumentResult,
    stateNoticeSearchAsyncAutoCompleteFieldProps,
    submitStateNoticeDocument,
    initStateNoticeDocument,
  } = useStateNoticeDocumentFiling({
    document,
    applicationId,
    setApplicationId,
    associatedChecklistItem,
    setAssociatedChecklistItem,
    newDocumentName,
    setNewDocumentName,
    searchValueRef,
    autoFillDataRef,
    documentVerifyDTO,
    setDocumentVerifyDTO,
  });

  const { errors, documentErrors } = useFiaFilingFormCard(fiaRequest);
  useEffect(() => {
    // Reset Document Name on document change/new filing process
    if (document?.id) {
      setNewDocumentName(document.originalFileName);
    }

    // Process FIA/State Notice Document as appropriate, else set results to undefined
    if (
      document?.id &&
      filingStrategy &&
      filingStrategy === FilingStrategy.FIADocument
    ) {
      processFIADocument(document?.id);
    } else if (
      document?.id &&
      filingStrategy &&
      filingStrategy === FilingStrategy.StateNoticeDocument &&
      stateNoticeFilingProcessDocumentResult
    ) {
      initStateNoticeDocument(stateNoticeFilingProcessDocumentResult);
    } else {
      setFiaProcessDocumentResult(undefined);
      setStateNoticeFilingProcessDocumentResult(undefined);
    }
  }, [document?.id]);

  // Document Filing Queue
  // Refresh documentFilingGridRef.current.refresh() evert 30 seconds
  const documentFilingQueueGridRef = useRef<PrimaryGridModule>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      documentFilingQueueGridRefresh();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const documentFilingQueueGridRefresh = () => {
    documentFilingQueueGridRef.current
      ?.getGridModule()
      ?.getGridRef()
      ?.refresh();
  };

  const documentFilingQueueGridContextMenuItems = [
    {
      text: "Delete",
      target: ".e-rowcell",
      id: "delete-row",
    },
  ];

  const documentFilingQueueGridContextMenuClick = async (
    args: MenuEventArgs & {
      rowInfo: {
        rowData: DocumentFilingQueueODataReadDTO;
      };
    }
  ) => {
    if (args.item.id === "delete-row" && args?.rowInfo.rowData) {
      dialogsViewerStore.confirm({
        content: <>Are you sure you would like to delete this document?</>,
        onClose: () => {
          null;
        },
        onConfirm: async () => {
          const result = {
            isSuccess: true,
            error: undefined,
          };

          documentFilingAIClient
            .documentFilingDequeue(args?.rowInfo.rowData.id)
            .then(() => {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Document Deleted.
                  </div>
                ),
                intent: Intent.SUCCESS,
              });

              documentFilingQueueGridRefresh();
              props?.onDocumentDeleted?.();
            })
            .catch((error) => {
              result.isSuccess = false;
              result.error = error;
            });

          return result;
        },
      });
    }
  };

  const processQueueItem = (queue: DocumentFilingQueueODataReadDTO) => {
    if (!queue.stateNoticeFilingProcessDocumentResult) {
      return;
    }

    const result = JSON.parse(
      queue.stateNoticeFilingProcessDocumentResult
    ) as StateNoticeFilingProcessDocumentResult;

    if (!result?.processId) {
      console.error("Document Filing Data Error", result);
      AppToaster.show({
        message: (
          <div>
            <h3>Document Filing Error</h3>
            Document filing process data not found.
          </div>
        ),
        intent: Intent.DANGER,
      });
      return;
    }

    setFilingStrategy(FilingStrategy.StateNoticeDocument);
    setAsyncAutoCompleteProps(stateNoticeSearchAsyncAutoCompleteFieldProps);
    setStateNoticeFilingProcessDocumentResult({ ...result });
    setDocument({
      id: queue.documentId,
      originalFileName: queue.document_FileName,
    } as Document);

    if (result.applicationData?.id) {
      setApplication({ ...result.applicationData });
    }
  };

  const autoNextQueueItem = async (queue: DocumentFilingQueueODataReadDTO) => {
    if (!queue.isReadyToFile) {
      return false;
    }

    if (!queue.stateNoticeFilingProcessDocumentResult) {
      console.error(
        "Document Filing Error",
        queue.stateNoticeFilingProcessDocumentResult
      );
      return false;
    }

    try {
      processQueueItem(queue);

      setTimeout(() => {
        AppToaster.show({
          message: (
            <div>
              <h3>Auto Next State Notice</h3>
              The system is now displaying the next State Notice waiting to be
              Filed
            </div>
          ),
          intent: Intent.PRIMARY,
        });
      }, 1000);
      return true;
    } catch (error) {
      console.error("Could not auto next queue item", error);
      return false;
    }
  };

  const documentFilingQueueGridRecordClick = (
    args: GridRecordClickEventArgs<DocumentFilingQueueODataReadDTO>
  ) => {
    if (!args.rowData.isReadyToFile) {
      return;
    }

    if (!args.rowData.stateNoticeFilingProcessDocumentResult) {
      console.error(
        "Document Filing Error",
        args.rowData.stateNoticeFilingProcessDocumentResult
      );
      AppToaster.show({
        message: (
          <div>
            <h3>Document Filing Error</h3>
            Document filing process not found.
          </div>
        ),
        intent: Intent.DANGER,
      });
      return;
    }

    processQueueItem(args.rowData);
  };

  // Functional/Rendering UI Flags
  const isUploadDocumentStage =
    isUndefined(document?.id) && isUndefined(filingStrategy);

  const isProcessDocumentStage =
    !isUndefined(document?.id) &&
    !isUndefined(filingStrategy) &&
    isUndefined(fiaProcessDocumentResult) &&
    isUndefined(stateNoticeFilingProcessDocumentResult);

  const isFormStage =
    !isUndefined(document?.id) &&
    !isUndefined(filingStrategy) &&
    (!isUndefined(fiaProcessDocumentResult) ||
      !isUndefined(stateNoticeFilingProcessDocumentResult));

  const canBeFiled =
    document?.id &&
    (fiaRequest?.id || application?.id) &&
    associatedChecklistItem?.id &&
    (filingStrategy === FilingStrategy.FIADocument
      ? isFiaFilingValid(documentVerifyDTO)
      : true);

  const handleValidationsCheck = async (
    isDuplicate: boolean,
    isBypass: boolean
  ) => {
    const content = (): ReactNode => {
      if (isDuplicate && isBypass) {
        return (
          <>
            <div>
              Potential duplicate document detected. This document may already
              be on the file. Are you sure you want to file this document?
            </div>
            <br />
            <div>
              The FIA Request is missing Required Documents:
              <ul>
                {documentErrors.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
              <br />
              Are you sure you want to resend?
            </div>
          </>
        );
      }
      if (isDuplicate) {
        return (
          <div>
            Potential duplicate document detected. This document may already be
            on the file. Are you sure you want to file this document?
          </div>
        );
      }

      if (isBypass) {
        return (
          <div>
            The FIA Request is missing Required Documents:
            <ul>
              {documentErrors.map((message) => (
                <li key={message}>{message}</li>
              ))}
            </ul>
            <br />
            Are you sure you want to resend?
          </div>
        );
      }
    };

    dialogsViewerStore.confirm({
      content: content(),
      onClose: () => {
        null;
      },
      onConfirm: async () => {
        const result = {
          isSuccess: true,
          error: undefined,
        };

        try {
          const resultFiling = await handleDocumentFiling(isBypass);

          if (!resultFiling) {
            result.isSuccess = false;
            result.error = undefined;
          }
        } catch (error: any) {
          result.isSuccess = false;
          result.error = error;
        }

        return result;
      },
    });
  };

  const fileDocument = async (isBypass: boolean) => {
    if (filingStrategy === FilingStrategy.FIADocument) {
      const result = await submitFiaDocument(isBypass);

      if (result) {
        resetDocumentFIling();
        return true;
      }

      return false;
    } else if (filingStrategy === FilingStrategy.StateNoticeDocument) {
      const stateNoticeResult = await submitStateNoticeDocument();

      if (stateNoticeResult === null) {
        resetDocumentFIling();

        return true;
      } else if (stateNoticeResult) {
        resetDocumentFIling();

        const result = await autoNextQueueItem(stateNoticeResult);
        if (!result) {
          resetDocumentFIling();
          return true;
        }
        return result;
      }
    }
  };

  const handleDocumentFiling = async (isBypass: boolean) => {
    setIsSaving(true);

    const result = await fileDocument(isBypass);

    if (result) {
      props?.onDocumentFiled?.();
    }

    setIsSaving(false);

    return result;
  };

  const handleSubmitDocument = async () => {
    setIsSaving(true);
    if (!document || !applicationId) {
      return;
    }

    const isDuplicate =
      await applicationApiClient.checkForRecentDuplicateApplicationDocuments(
        applicationId,
        document?.id
      );

    const isBypass =
      documentErrors &&
      documentErrors.length > 0 &&
      documentVerifyDTO.fiaRequestAction ==
        FIARequestFollowUpActionTypes.Resend;

    if (isDuplicate || isBypass) {
      await handleValidationsCheck(isDuplicate, isBypass);
    } else {
      await handleDocumentFiling(false);
    }
    setIsSaving(false);
  };

  const [asyncAutoCompleteProps, setAsyncAutoCompleteProps] =
    useState<AsyncAutoCompleteFieldProps>(fiaSearchAsyncAutoCompleteFieldProps);

  // Reset any state managed data on document filing complete or reset
  const resetDocumentFIling = () => {
    filingAIEntityId.current = uuidv4();
    setDocument(undefined);
    setFiaRequest(undefined);
    setAssociatedChecklistItem(undefined);
    setFilingStrategy(undefined);
    setApplication(undefined);
    setApplicationId(undefined);
    setFiaProcessDocumentResult(undefined);
    setNewDocumentName(undefined);
    setStateNoticeFilingProcessDocumentResult(undefined);
    setDocumentVerifyDTO({ ...newDocumentVerifyDTO });
    searchValueRef.current = undefined;
    autoFillDataRef.current = undefined;
  };

  return {
    filingAIEntityId: filingAIEntityId.current,
    document,
    setDocument,
    stages: {
      isUploadDocumentStage,
      isProcessDocumentStage,
      isFormStage,
    },
    fiaRequest,
    associatedChecklistItem,
    setAssociatedChecklistItem,
    canBeFiled,
    fiaSearchAsyncAutoCompleteFieldProps,
    submitFiaDocument,
    submitStateNoticeDocument,
    fiaProcessDocumentResult,
    documentVerifyDTO,
    setDocumentVerifyDTO,
    searchValue: searchValueRef.current,
    autoFillData: autoFillDataRef.current,
    filingStrategy,
    setFilingStrategy,
    application,
    stateNoticeFilingProcessDocumentResult,
    stateNoticeSearchAsyncAutoCompleteFieldProps,
    asyncAutoCompleteProps,
    setAsyncAutoCompleteProps,
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    resetDocumentFIling,
    newDocumentName,
    setNewDocumentName,
    isSaving,
    setIsSaving,
    handleSubmitDocument,
    errors,
    documentErrors,
    fiaNeedsFollowUp,
    documentFilingQueueGridRef,
    documentFilingQueueGridRefresh,
    documentFilingQueueGridContextMenuItems,
    documentFilingQueueGridContextMenuClick,
    documentFilingQueueGridRecordClick,
  };
};

export default useDocumentFiling;
