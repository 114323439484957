import { Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { ExistingTemplate } from "../../types/Template";
import { commentStore } from "../../stores/CommentStore";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import {
  OrderedDocumentCoverLetterCreateDTO,
  OrderedDocumentCreateDTO,
} from "../OrderedDocumentsGrid/configurations/types";
import { AppToaster } from "../Toast/Toast";
import { ApplicationChildCreationDialog } from "./ApplicationChildCreationDialog";
import { ApplicationSummaryDialog } from "./ApplicationSummaryDialog";
import { ApplyToStateDialog } from "./ApplyToStateDialog";
import { BillingCreateDialog } from "./BillingCreateDialog";
import { BillingRuleCreateDialog } from "./BillingRuleCreateDialog";
import { BillingRuleUpdateDialog } from "./BillingRuleUpdateDialog";
import { BillingUpdateDialog } from "./BillingUpdateDialog";
import { ChecklistItemCreationDialog } from "./ChecklistItemCreationDialog";
import { ChecklistItemUpdateDialog } from "./ChecklistItemUpdateDialog";
import { CommentCreationDialog } from "./CommentCreationDialog";
import { CommentsDialog } from "./CommentsDialog/CommentsDialog";
import { CommentUpdateDialog } from "./CommentUpdateDialog";
import { ConfirmDialog } from "./ConfirmDialog";
import { CorporateCreationDialog } from "./CorporateCreationDialog";
import { CorporateUpdateDialog } from "./CorporateUpdateDialog";
import { DiscountCreationDialog } from "./DiscountCreationDialog";
import { DiscountUpdateDeleteDialog } from "./DiscountUpdateDeleteDialog";
import { FacilityCreationDialog } from "./FacilityCreationDialog";
import { FacilityUpdateDialog } from "./FacilityUpdateDialog";
import { InstitutionCreationDialog } from "./InstitutionCreationDialog";
import { InstitutionUpdateDialog } from "./InstitutionUpdateDialog";
import { InvoiceDocumentHistoryDialog } from "./InvoiceDocumentHistoryDialog";
import { InvoiceGenerationAttestation } from "./InvoiceGenerationAttestation";
import { MonitorCreationDialog } from "./MonitorCreationDialog";
import { MonitorUpdateDialog } from "./MonitorUpdateDialog";
import { NotaryCreationDialog } from "./NotaryCreationDialog";
import { NotaryUpdateDialog } from "./NotaryUpdateDialog";
import { OrderedDocumentsPickerDialog } from "./OrderedDocumentsPickerDialog";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import "./styles.scss";
import { TemplateCreationDialog } from "./TemplateCreationDialog";
import { TemplateUpdateDialog } from "./TemplateUpdateDialog";
import { UserCreationDialog } from "./UserCreationDialog";
import { UserUpdateDialog } from "./UserUpdateDialog";
import { UpdateSignatureDialog } from "./UpdateSignatureDialog";
import { WithdrawApplicationDialog } from "./WithdrawApplicationDialog";
import { FIARequestCancelDialog } from "./FIARequestCancelDialog";
import { generateKey } from "../../utils/miscellaneous";
import { DocumentViewerDialog } from "./DocumentViewerDialog";
import { ImageViewerDialog } from "./ImageViewerDialog";
import { DocumentEditorDialog } from "./DocumentEditorDialog";
import { EndMonitorDialog } from "./EndMonitorDialog";
import { ApplicationPageUpdateFacilityDialog } from "./ApplicationPageUpdateFacilityDialog";
import { FacilityUsersGridDialog } from "./GridDialogs/FacilityUsers/FacilityUsersGridDialog";
import { CorporateFacilitiesGridDialog } from "./GridDialogs/CorporateFacilities/CorporateFacilitiesGridDialog";
import { CoverLetterPickerDialog } from "./CoverLetterPickerDialog";
import { CorporateUsersGridDialog } from "./GridDialogs/CorporateUsers/CorporateUsersGridDialog";
import { CorporateApplicationsGridDialog } from "./GridDialogs/CorporateApplications/CorporateApplicationsGridDialog";
import { FacilityApplicationsGridDialog } from "./GridDialogs/FacilityApplications/FacilityApplicationsGridDialog";
import { UserApplicationsGridDialog } from "./GridDialogs/UserApplications/UserApplicationsGridDialog";
import { SetInvoiceNumberDialog } from "./SetInvoiceNumberDialog";

import { ChecklistSplitDialog } from "./ChecklistSplitDialog";
import { DocumentRenameDialog } from "./DocumentRenameDialog";
import { HelpCenterTopicDialog } from "./HelpCenterTopicDialog";
import { ReportDialog } from "./ReportDialog";
import { FinancialEligibilityConversationDialog } from "./FinancialEligibilityConversationDialog";
import { LeadEditDialog } from "./LeadEditDialog";
import { CancelAllFIARequestsDialog } from "./CancelAllFIARequestsDialog";
import { ChecklistSplitAddOwnedEntityDialog } from "./ChecklistSplitAddOwnedEntityDialog";
import { MatchupDocumentUploadDialog } from "./MatchupDocumentUploadDialog";
import { LeadDocumentCreateDTO } from "../Matchup/types";
import { matchupApiClient } from "../../lib/apiClients/matchup/matchupApiClient";
import { FIARequestResendDialog } from "./FIARequestResendDialog";
import { VerifyDialog } from "./VerifyDialog";
import { DocumentFilingDialog } from "../DocumentFiling/DocumentFilingDialog";

export const Dialogs = observer(() => {
  return (
    <>
      {dialogsViewerStore.isUserCreationDialogOpen && (
        <UserCreationDialog
          key={generateKey()}
          isOpen={dialogsViewerStore.isUserCreationDialogOpen}
          closeDialog={() =>
            dialogsViewerStore.setIsUserCreationDialogOpen(false)
          }
          submitDialog={async () => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>User Created.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            dialogsViewerStore.setIsUserCreationDialogOpen(false);
          }}
        />
      )}
      <UserUpdateDialog
        isOpen={dialogsViewerStore.isUserUpdateDialogOpen}
        closeDialog={() => dialogsViewerStore.setIsUserUpdateDialogOpen(false)}
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>User Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsUserUpdateDialogOpen(false);
        }}
      />
      {dialogsViewerStore.isUpdateSignatureDialogOpen && (
        <UpdateSignatureDialog
          isOpen={dialogsViewerStore.isUpdateSignatureDialogOpen}
          closeDialog={() =>
            dialogsViewerStore.setIsUpdateSignatureDialogOpen(false)
          }
          submitDialog={async () => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>User Signature Updated.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            dialogsViewerStore.setIsUpdateSignatureDialogOpen(false);
          }}
        />
      )}
      <CorporateCreationDialog
        isOpen={dialogsViewerStore.isCorporateCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCorporateCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Corporate Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsCorporateCreationDialogOpen(false);
        }}
      />
      <CorporateUpdateDialog
        isOpen={dialogsViewerStore.isCorporateUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCorporateUpdateDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Corporate Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsCorporateUpdateDialogOpen(false);
        }}
      />
      <FacilityCreationDialog
        isOpen={dialogsViewerStore.isFacilityCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsFacilityCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Facility Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsFacilityCreationDialogOpen(false);
        }}
      />
      <FacilityUpdateDialog
        isOpen={dialogsViewerStore.isFacilityUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsFacilityUpdateDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Facility Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsFacilityUpdateDialogOpen(false);
        }}
      />
      <InstitutionCreationDialog
        isOpen={dialogsViewerStore.isInstitutionCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsInstitutionCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Institution Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsInstitutionCreationDialogOpen(false);
        }}
      />
      <InstitutionUpdateDialog
        isOpen={dialogsViewerStore.isInstitutionUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsInstitutionUpdateDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Institution Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsInstitutionUpdateDialogOpen(false);
        }}
      />
      <NotaryCreationDialog
        isOpen={dialogsViewerStore.isNotaryCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsNotaryCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Notary Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsNotaryCreationDialogOpen(false);
        }}
      />
      <NotaryUpdateDialog
        key={`notary-update-dialog-${dialogsViewerStore?.notaryUpdateDialogOpenOptions?.notaryId}`}
        isOpen={dialogsViewerStore.isNotaryUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsNotaryUpdateDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Notary Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsNotaryUpdateDialogOpen(false);
        }}
      />
      <TemplateCreationDialog
        isOpen={dialogsViewerStore.isTemplateCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsTemplateCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Template Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsTemplateCreationDialogOpen(false);
        }}
      />
      <TemplateUpdateDialog
        key={`template-update-dialog-${dialogsViewerStore?.templateUpdateDialogOptions?.templateId}`}
        isOpen={dialogsViewerStore.isTemplateUpdateDialogOpen}
        closeDialog={() => dialogsViewerStore.closeTemplateUpdateDialog()}
        templateId={
          dialogsViewerStore?.templateUpdateDialogOptions?.templateId ?? ""
        }
        submitDialog={async (args?: ExistingTemplate) => {
          // Show Toast
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Template Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });

          // Launch Event and Close
          !!dialogsViewerStore?.templateUpdateDialogOptions?.onSubmit &&
            dialogsViewerStore.templateUpdateDialogOptions.onSubmit(args);

          dialogsViewerStore.setIsTemplateUpdateDialogOpen(false);
        }}
      />
      <ConfirmDialog
        key={`confirm-dialog-${dialogsViewerStore.isConfirmDialogOpen.toString()}`}
        isOpen={dialogsViewerStore.isConfirmDialogOpen}
        content={dialogsViewerStore?.confirmDialogOptions?.content}
        closeDialog={() => {
          dialogsViewerStore?.confirmDialogOptions?.onClose &&
            dialogsViewerStore?.confirmDialogOptions?.onClose();
          dialogsViewerStore.setIsConfirmDialogOpen(false);
        }}
        onConfirm={dialogsViewerStore?.confirmDialogOptions?.onConfirm}
        shouldHideCancelButton={
          dialogsViewerStore?.confirmDialogOptions?.shouldHideCancelButton
        }
        confirmButtonText={
          dialogsViewerStore?.confirmDialogOptions?.confirmButtonText
        }
        confirmButtonIntent={
          dialogsViewerStore.confirmDialogOptions?.confirmButtonIntent
        }
        confirmHeaderText={
          dialogsViewerStore.confirmDialogOptions?.confirmHeaderText
        }
        rightSideDisplay={
          dialogsViewerStore.confirmDialogOptions?.rightSideDisplay
        }
      />
      <OrderedDocumentsPickerDialog
        key={`ordered-documents-picker-dialog-${dialogsViewerStore?.orderedDocumentsPickerDialogOptions?.pdfDocumentEntity?.entityId}`}
        pdfDocumentEntity={
          dialogsViewerStore.orderedDocumentsPickerDialogOptions
            ?.pdfDocumentEntity
        }
        templateOdataExcludeIds={
          dialogsViewerStore.orderedDocumentsPickerDialogOptions
            ?.templateOdataExcludeIds
        }
        isOpen={dialogsViewerStore.isOrderedDocumentsPickerDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsOrderedDocumentsPickerDialogOpen(false)
        }
        submitDialog={async (
          orderedDocumentCreateDTO?: OrderedDocumentCreateDTO[]
        ) => {
          // TODO
          // Launch Event and Close
          orderedDocumentCreateDTO?.forEach((orderedDocument) => {
            !!dialogsViewerStore?.orderedDocumentsPickerDialogOptions
              ?.onSubmit &&
              dialogsViewerStore.orderedDocumentsPickerDialogOptions.onSubmit(
                orderedDocument
              );
          });

          dialogsViewerStore.setIsOrderedDocumentsPickerDialogOpen(false);
        }}
      />
      <CoverLetterPickerDialog
        key={`cover-letter-picker-dialog-${dialogsViewerStore?.coverLetterPickerDialogOptions?.pdfDocumentEntity?.entityId}`}
        pdfDocumentEntity={
          dialogsViewerStore.coverLetterPickerDialogOptions?.pdfDocumentEntity
        }
        isOpen={dialogsViewerStore.isCoverLetterPickerDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCoverLetterPickerDialogOpen(false)
        }
        submitDialog={async (
          orderedDocumentCoverLetterCreateDTO?: OrderedDocumentCoverLetterCreateDTO
        ) => {
          !!dialogsViewerStore?.coverLetterPickerDialogOptions?.onSubmit &&
            dialogsViewerStore.coverLetterPickerDialogOptions.onSubmit(
              orderedDocumentCoverLetterCreateDTO
            );

          dialogsViewerStore.setIsCoverLetterPickerDialogOpen(false);
        }}
      />
      <DocumentEditorDialog
        key={`document-editor-dialog-${dialogsViewerStore?.documentEditorDialogOptions?.documentId}`}
        documentId={dialogsViewerStore?.documentEditorDialogOptions?.documentId}
        isOpen={dialogsViewerStore.isDocumentEditorDialogOpen}
        closeDialog={function (): void {
          dialogsViewerStore.setIsDocumentEditorDialogOpen(false);
        }}
        submitDialog={function (): void {
          dialogsViewerStore.documentEditorDialogOptions?.onUpdate();
          dialogsViewerStore.setIsDocumentEditorDialogOpen(false);
        }}
      />
      <DocumentRenameDialog
        key={`document-rename-dialog-${dialogsViewerStore?.documentRenameDialogOptions?.documentId}`}
        documentId={dialogsViewerStore?.documentRenameDialogOptions?.documentId}
        isOpen={dialogsViewerStore.isDocumentRenameDialogOpen}
        closeDialog={function (): void {
          dialogsViewerStore.setIsDocumentRenameDialogOpen(false);
        }}
        submitDialog={function (): void {
          dialogsViewerStore.documentRenameDialogOptions?.onUpdate();
          dialogsViewerStore.setIsDocumentRenameDialogOpen(false);
        }}
      />
      {dialogsViewerStore.isDocumentViewerDialogOpen && (
        <DocumentViewerDialog
          key={`document-viewer-dialog-${dialogsViewerStore?.documentViewerDialogOptions?.key}`}
          getDocument={
            dialogsViewerStore?.documentViewerDialogOptions?.getDocument
          }
          isOpen={dialogsViewerStore.isDocumentViewerDialogOpen}
          closeDialog={function (): void {
            dialogsViewerStore.setIsDocumentViewerDialogOpen(false);
          }}
          submitDialog={function (): void {
            dialogsViewerStore.setIsDocumentViewerDialogOpen(false);
          }}
        />
      )}
      <WithdrawApplicationDialog
        isOpen={dialogsViewerStore.isWithdrawApplicationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsWithdrawApplicationDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <ApplicationChildCreationDialog
        isOpen={dialogsViewerStore.isApplicationChildCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsApplicationChildCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Application Child Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsApplicationChildCreationDialogOpen(false);
        }}
      />
      <MonitorCreationDialog
        isOpen={dialogsViewerStore.isMonitorCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsMonitorCreationDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Monitor Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsMonitorCreationDialogOpen(false);
        }}
      />
      <MonitorUpdateDialog
        isOpen={dialogsViewerStore.isMonitorUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsMonitorUpdateDialogOpen(false)
        }
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Monitor Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsMonitorUpdateDialogOpen(false);
        }}
      />
      <EndMonitorDialog
        isOpen={dialogsViewerStore.isEndMonitorDialogOpen}
        closeDialog={() => dialogsViewerStore.setIsEndMonitorDialogOpen(false)}
        submitDialog={async () => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Monitor Deleted.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsEndMonitorDialogOpen(false);
        }}
      />
      {dialogsViewerStore.isChecklistItemCreationDialogOpen && (
        <ChecklistItemCreationDialog
          isOpen={dialogsViewerStore.isChecklistItemCreationDialogOpen}
          closeDialog={() =>
            dialogsViewerStore.setIsChecklistItemCreationDialogOpen(false)
          }
          onSuccess={
            dialogsViewerStore.checklistItemCreationDialogOpenOptions?.onSubmit
          }
          submitDialog={() => 0}
        />
      )}
      <ChecklistItemUpdateDialog
        isOpen={dialogsViewerStore.isChecklistItemUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsChecklistItemUpdateDialogOpen(false)
        }
        onSuccess={
          dialogsViewerStore.checklistItemUpdateDialogOpenOptions?.onSuccess
        }
        submitDialog={() => 0}
      />
      <ChecklistSplitDialog
        isOpen={dialogsViewerStore.isChecklistSplitDialogOpen}
        applicationId={
          dialogsViewerStore.checklistSplitDialogOpenOptions?.applicationId
        }
        checklistItemId={
          dialogsViewerStore.checklistSplitDialogOpenOptions?.checklistItemId
        }
        onClose={dialogsViewerStore.checklistSplitDialogOpenOptions?.onClose}
        closeDialog={() =>
          dialogsViewerStore.checklistSplitDialogOpenOptions?.onClose
        }
        submitDialog={() =>
          dialogsViewerStore.checklistSplitDialogOpenOptions?.onClose
        }
      />
      <HelpCenterTopicDialog
        isOpen={dialogsViewerStore.isHelpCenterTopicDialogOpen}
        sectionType={
          dialogsViewerStore.helpCenterTopicDialogOpenOptions?.sectionType
        }
        usState={dialogsViewerStore.helpCenterTopicDialogOpenOptions?.usState}
        id={dialogsViewerStore.helpCenterTopicDialogOpenOptions?.id}
        onClose={dialogsViewerStore.helpCenterTopicDialogOpenOptions?.onClose}
        closeDialog={() =>
          dialogsViewerStore.helpCenterTopicDialogOpenOptions?.onClose
        }
        submitDialog={() =>
          dialogsViewerStore.helpCenterTopicDialogOpenOptions?.onClose
        }
      />
      {dialogsViewerStore.isReportDialogOpen && (
        <ReportDialog
          isOpen={dialogsViewerStore.isReportDialogOpen}
          id={dialogsViewerStore.reportDialogOpenOptions?.id}
          onClose={dialogsViewerStore.reportDialogOpenOptions?.onClose}
          closeDialog={() =>
            dialogsViewerStore.reportDialogOpenOptions?.onClose
          }
          submitDialog={() =>
            dialogsViewerStore.reportDialogOpenOptions?.onClose
          }
        />
      )}
      <CommentCreationDialog
        isOpen={dialogsViewerStore.isCommentCreationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCommentCreationDialogOpen(false)
        }
        submitDialog={() => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Comment Created.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsCommentCreationDialogOpen(false);
        }}
      />
      <CommentUpdateDialog
        isOpen={dialogsViewerStore.isCommentUpdateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCommentUpdateDialogOpen(false)
        }
        submitDialog={() => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Comment Updated.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
          dialogsViewerStore.setIsCommentUpdateDialogOpen(false);
        }}
      />
      <DiscountCreationDialog
        isOpen={dialogsViewerStore.isDiscountCreationDialogOpen}
        corporateId={
          dialogsViewerStore.discountCreationDialogOpenOptions?.corporateId
        }
        closeDialog={() => {
          dialogsViewerStore.setIsDiscountCreationDialogOpen(false);
        }}
        submitDialog={() => {
          dialogsViewerStore.setIsDiscountCreationDialogOpen(false);
        }}
      />
      <DiscountUpdateDeleteDialog
        isOpen={dialogsViewerStore.isDiscountUpdateDeleteDialogOpen}
        discount={
          dialogsViewerStore.discountUpdateDeleteDialogOpenOptions?.discount
        }
        closeDialog={() => {
          dialogsViewerStore.setIsDiscountUpdateDeleteDialogOpen(false);
        }}
        submitDialog={() => {
          dialogsViewerStore.setIsDiscountUpdateDeleteDialogOpen(false);
        }}
      />
      <BillingRuleUpdateDialog
        isOpen={dialogsViewerStore.isBillingRuleUpdateDialogOpen}
        billingRule={
          dialogsViewerStore.billingRuleUpdateDialogOpenOptions?.billingRule
        }
        closeDialog={() => {
          dialogsViewerStore.setIsBillingRuleUpdateDialogOpen(false);
        }}
        submitDialog={() => {
          dialogsViewerStore.setIsBillingRuleUpdateDialogOpen(false);
        }}
      />
      <BillingRuleCreateDialog
        isOpen={dialogsViewerStore.isBillingRuleCreateDialogOpen}
        corporateId={
          dialogsViewerStore.billingRuleCreateDialogOpenOptions?.corporateId
        }
        closeDialog={() => {
          dialogsViewerStore.setIsBillingRuleCreateDialogOpen(false);
        }}
        submitDialog={() => {
          dialogsViewerStore.setIsBillingRuleCreateDialogOpen(false);
        }}
      />
      <BillingCreateDialog
        isOpen={dialogsViewerStore.isBillingCreateDialogOpen}
        closeDialog={() => {
          dialogsViewerStore.setIsBillingCreateDialogOpen(false);
        }}
        submitDialog={() => {
          dialogsViewerStore.setIsBillingCreateDialogOpen(false);
        }}
      />
      <BillingUpdateDialog
        isOpen={dialogsViewerStore.isUpdateBillingDialogOpen}
        closeDialog={() => {
          dialogsViewerStore.setIsUpdateBillingDialogOpen(false);
        }}
        submitDialog={() => {
          dialogsViewerStore.setIsUpdateBillingDialogOpen(false);
        }}
      />
      <CommentsDialog
        isOpen={dialogsViewerStore.isCommentsDialogOpen}
        applicationId={
          dialogsViewerStore.commentsDialogOpenOptions?.applicationId
        }
        applicationFileNumber={
          dialogsViewerStore.commentsDialogOpenOptions?.applicationFileNumber
        }
        sourceId={dialogsViewerStore.commentsDialogOpenOptions?.sourceId}
        sourceName={dialogsViewerStore.commentsDialogOpenOptions?.sourceName}
        isSrcApplication={
          dialogsViewerStore.commentsDialogOpenOptions?.isSrcApplication
        }
        closeDialog={async () => {
          await commentStore.setComments();
          if (gridStore.gridKey === "application-board-grid") {
            gridStore.refresh();
          }

          if (!!dialogsViewerStore.commentsDialogOpenOptions?.onSuccess) {
            dialogsViewerStore.commentsDialogOpenOptions?.onSuccess();
          }

          dialogsViewerStore.setIsCommentsDialogOpen(false);
        }}
        submitDialog={() => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>Comment Added.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
        }}
      />
      <InvoiceGenerationAttestation
        isOpen={dialogsViewerStore.isInvoiceGenerationDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsInvoiceGenerationDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <InvoiceDocumentHistoryDialog
        isOpen={dialogsViewerStore.isInvoiceDocumentHistoryDialogOpen}
        invoiceId={
          dialogsViewerStore.invoiceDocumentHistoryDialogOpenOptions?.invoiceId
        }
        invoiceDocumentId={
          dialogsViewerStore.invoiceDocumentHistoryDialogOpenOptions
            ?.invoiceDocumentId
        }
        closeDialog={() =>
          dialogsViewerStore.setIsInvoiceDocumentHistoryDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <ApplicationSummaryDialog
        isOpen={dialogsViewerStore.isApplicationSummaryDialogOpen}
        applicationId={
          dialogsViewerStore.applicationSummaryDialogOpenOptions?.applicationId
        }
        closeDialog={() =>
          dialogsViewerStore.setIsApplicationSummaryDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <ApplyToStateDialog
        isOpen={dialogsViewerStore.isApplyToStateDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsApplyToStateDialogOpen(false)
        }
        submitDialog={async () => 0}
      />
      <FIARequestCancelDialog
        key={`fia-request-cancel-dialog-${dialogsViewerStore.fiaRequestCancelDialogOpenOptions?.fiaRequestId}`}
        isOpen={dialogsViewerStore.isFIARequestCancelDialogOpen}
        fiaRequestId={
          dialogsViewerStore.fiaRequestCancelDialogOpenOptions?.fiaRequestId
        }
        applicationId={
          dialogsViewerStore.fiaRequestCancelDialogOpenOptions?.applicationId
        }
        readOnly={
          dialogsViewerStore.fiaRequestCancelDialogOpenOptions?.readOnly
        }
        cancelReasons={
          dialogsViewerStore.fiaRequestCancelDialogOpenOptions?.cancelReasons
        }
        setIsCancelLoading={
          dialogsViewerStore.fiaRequestCancelDialogOpenOptions
            ?.setIsCancelLoading
        }
        closeDialog={() =>
          dialogsViewerStore.setIsFIARequestCancelDialogOpen(false)
        }
        onSuccessfulCancel={
          dialogsViewerStore.fiaRequestCancelDialogOpenOptions
            ?.onSuccessfulCancel
        }
        submitDialog={() => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>FIA Request Canceled.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
        }}
      />

      <CancelAllFIARequestsDialog
        key={`cancel-all-fia-requests-dialog-${dialogsViewerStore.cancelAllFIARequestsDialogOpenOptions?.applicationId}`}
        isOpen={dialogsViewerStore.isCancelAllFIARequestsDialogOpen}
        applicationId={
          dialogsViewerStore.cancelAllFIARequestsDialogOpenOptions
            ?.applicationId
        }
        setIsCancelLoading={
          dialogsViewerStore.cancelAllFIARequestsDialogOpenOptions
            ?.setIsCancelLoading
        }
        onSuccessfulCancel={
          dialogsViewerStore.cancelAllFIARequestsDialogOpenOptions
            ?.onSuccessfulCancel
        }
        closeDialog={() =>
          dialogsViewerStore.setIsCancelAllFIARequestsDialogOpen(false)
        }
        submitDialog={() => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>All Pending FIA Requests for this application
                have been canceled.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
        }}
      />

      <FIARequestResendDialog
        key={`fia-request-resend-dialog-${dialogsViewerStore.isFIARequestResendDialogOpen.toString()}`}
        isOpen={dialogsViewerStore.isFIARequestResendDialogOpen}
        fiaRequestId={
          dialogsViewerStore.isFIARequestResendDialogOpenOptions?.fiaRequestId
        }
        applicationId={
          dialogsViewerStore.isFIARequestResendDialogOpenOptions?.applicationId
        }
        institution={
          dialogsViewerStore.isFIARequestResendDialogOpenOptions?.institution
        }
        setIsResendLoading={
          dialogsViewerStore.isFIARequestResendDialogOpenOptions
            ?.setIsResendLoading
        }
        isBypass={
          dialogsViewerStore.isFIARequestResendDialogOpenOptions?.isBypass
        }
        closeDialog={() =>
          dialogsViewerStore.setIsFIARequestResendDialogOpen(false)
        }
        submitDialog={() => {
          AppToaster.show({
            message: (
              <div>
                <h3>Success</h3>FIA Request Resent.
              </div>
            ),
            intent: Intent.SUCCESS,
          });
        }}
        onSuccessfulResend={
          dialogsViewerStore.isFIARequestResendDialogOpenOptions
            ?.onSuccessfulResend
        }
      />

      <ImageViewerDialog
        key={`image-viewer-dialog-${dialogsViewerStore.isImageViewerDialogOpen}`}
        isOpen={dialogsViewerStore.isImageViewerDialogOpen}
        closeDialog={() => dialogsViewerStore.setIsImageViewerDialogOpen(false)}
        submitDialog={() => 0}
      />
      <ApplicationPageUpdateFacilityDialog
        isOpen={dialogsViewerStore.isApplicationPageUpdateFacilityDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsApplicationPageUpdateFacilityDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <FacilityUsersGridDialog
        key={`facility-users-grid-dialog-${dialogsViewerStore.isFacilityUsersGridDialogOpen}`}
        isOpen={dialogsViewerStore.isFacilityUsersGridDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsFacilityUsersGridDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <CorporateFacilitiesGridDialog
        isOpen={dialogsViewerStore.isCorporateFacilitesGridDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCorporateFacilitesGridDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <CorporateUsersGridDialog
        isOpen={dialogsViewerStore.isCorporateFacilityUsersGridDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCorporateFacilityUsersGridDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <CorporateApplicationsGridDialog
        isOpen={dialogsViewerStore.isCorporateApplicationsGridDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsCorporateApplicationsGridDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <FacilityApplicationsGridDialog
        isOpen={dialogsViewerStore.isFacilityApplicationsGridDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsFacilityApplicationsGridDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <UserApplicationsGridDialog
        isOpen={dialogsViewerStore.isUserApplicationsGridDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsUserApplicationsGridDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <SetInvoiceNumberDialog
        isOpen={dialogsViewerStore.isSetInvoiceNumberDialogOpen}
        closeDialog={() =>
          dialogsViewerStore.setIsSetInvoiceNumberDialogOpen(false)
        }
        submitDialog={() => 0}
      />
      <FinancialEligibilityConversationDialog
        isOpen={dialogsViewerStore.isFinancialEligibilityConversationDialogOpen}
        financialEligibilityConversation={
          dialogsViewerStore.financialEligibilityConversationDialogOptions
            ?.financialEligibilityConversation
        }
        medicaidEligibilityDecisionType_AsString={
          dialogsViewerStore.financialEligibilityConversationDialogOptions
            ?.medicaidEligibilityDecisionType_AsString
        }
        closeDialog={() =>
          dialogsViewerStore.setFinancialEligibilityConversationDialogOpen(
            false
          )
        }
        submitDialog={() => 0}
      />
      <LeadEditDialog
        isOpen={dialogsViewerStore.isLeadEditDialogOpen}
        closeDialog={() => dialogsViewerStore.setIsLeadEditDialogOpen(false)}
        submitDialog={() => 0}
      />
      {dialogsViewerStore.isMatchupDocumentUploadDialogOpen && (
        <MatchupDocumentUploadDialog
          key={`matchup-documents-upload-dialog-${dialogsViewerStore?.matchupDocumentUploadDialogOpenOptions?.leadId}`}
          isOpen={dialogsViewerStore.isMatchupDocumentUploadDialogOpen}
          closeDialog={() =>
            dialogsViewerStore.setIsMatchupUploadDocumentDialogOpen(false)
          }
          submitDialog={async (
            leadDocumentCreateDTOs?: LeadDocumentCreateDTO[]
          ) => {
            if (leadDocumentCreateDTOs) {
              for (let i = 0; i < leadDocumentCreateDTOs.length; i++) {
                const leadDocumentCreateDTO = leadDocumentCreateDTOs[i];
                await matchupApiClient.addLeadDocument(
                  dialogsViewerStore.matchupDocumentUploadDialogOpenOptions
                    ?.leadId || "",
                  leadDocumentCreateDTO
                );
              }
            }
            dialogsViewerStore.matchupDocumentUploadDialogOpenOptions?.onSave();
            dialogsViewerStore.setIsMatchupUploadDocumentDialogOpen(false);
          }}
        />
      )}
      {dialogsViewerStore.isChecklistSplitAddOwnedEntityDialogOpen && (
        <ChecklistSplitAddOwnedEntityDialog
          key={`checklist-split-add-owned-entity-${dialogsViewerStore.isChecklistSplitAddOwnedEntityDialogOpen}`}
          isOpen={dialogsViewerStore.isChecklistSplitAddOwnedEntityDialogOpen}
          onSave={() => {
            dialogsViewerStore.checklistSplitAddOwnedEntityDialogOpenOptions?.onSave();
            dialogsViewerStore.setIsChecklistSplitAddOwnedEntityDialogOpen(
              false
            );
          }}
          closeDialog={() => {
            dialogsViewerStore.setIsChecklistSplitAddOwnedEntityDialogOpen(
              false
            );
          }}
          submitDialog={() => 0}
        />
      )}
      {dialogsViewerStore.isVerifyDialogOpen && (
        <VerifyDialog
          key={`checklist-split-add-owned-entity-${dialogsViewerStore.isVerifyDialogOpen}`}
          isOpen={dialogsViewerStore.isVerifyDialogOpen}
          onSave={() => {
            dialogsViewerStore.verifyDialogOpenOptions?.onSave();
          }}
          closeDialog={() => {
            dialogsViewerStore.setIsVerifyDialogOpen(false);
          }}
          submitDialog={() => {
            dialogsViewerStore.verifyDialogOpenOptions?.onSave();
            dialogsViewerStore.setIsVerifyDialogOpen(false);
          }}
          checklistItemId={
            dialogsViewerStore.verifyDialogOpenOptions?.checklistItemId
          }
          documentId={dialogsViewerStore.verifyDialogOpenOptions?.documentId}
        />
      )}
      {dialogsViewerStore.isDocumentFilingDialogOpen && (
        <DocumentFilingDialog
          key={`document-filing-dialog-${dialogsViewerStore.isDocumentFilingDialogOpen}`}
          isOpen={dialogsViewerStore.isDocumentFilingDialogOpen}
          closeDialog={() => {
            dialogsViewerStore.setIsDocumentFilingDialogOpen(false);
          }}
          submitDialog={() => {
            dialogsViewerStore.setIsDocumentFilingDialogOpen(false);
          }}
          events={dialogsViewerStore.documentFilingDialogOpenOptions}
        />
      )}
    </>
  );
});
