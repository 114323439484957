import { FormGroup, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { L10n } from "@syncfusion/ej2-base";
import {
  RemovingEventArgs,
  UploaderComponent,
  UploaderModel,
  UploadingEventArgs,
} from "@syncfusion/ej2-react-inputs";
import React, { useRef } from "react";
import variables from "../../../config/variables";
import { ExceptionDetails, ExceptionType } from "../../../lib/exceptions/types";
import { genericErrorMessage } from "../../../stores/ErrorStore";
import { Document } from "../../../types/Document";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import { AppToaster } from "../../Toast/Toast";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { FileFieldProps } from "../types/fieldTypes";
import "./styles.scss";

export const FileField: React.FC<FileFieldProps> = ({
  label,
  disabled,
  uploaderConfig,
  onSubmit,
  getAccessToken,
  onUploading,
  onChunkUploading,
  onCompleteUploading,
  onCompleteRemoving,
  onAllActionComplete,
  onRemoving,
  hidden,
  errorMessages,
  value,
  showPreview,
}) => {
  if (hidden) {
    return null;
  }

  let lastChunk: unknown = undefined;
  const [accessToken, setAccessToken] = React.useState<string | undefined>();
  const fileFieldRef = useRef<UploaderComponent>(null);

  React.useEffect(() => {
    L10n.load({
      default: {
        uploader: {
          invalidMaxFileSize: "File is too large, max file size is 50mbs",
        },
      },
    });
    setToken();
  }, []);

  const setToken = async () => {
    if (!!getAccessToken) {
      const token = await getAccessToken();
      setAccessToken(token);
    }
  };

  // Methods
  const onUploadingDefault = async (args: UploadingEventArgs) => {
    if (!args?.currentRequest) {
      return;
    }

    !!onUploading && onUploading(args);

    // Set Header
    setRequestHeader(args?.currentRequest);
  };

  const onChunckUploadingDefault = async (args: UploadingEventArgs) => {
    if (!args?.currentRequest) {
      return;
    }
    !!onChunkUploading && onChunkUploading(args, lastChunk);

    // Set Header
    setRequestHeader(args?.currentRequest);
  };

  const onRemovingDefault = async (args: RemovingEventArgs) => {
    if (!args?.currentRequest) {
      return;
    }

    !!onRemoving && onRemoving(args);

    // Set Header
    setRequestHeader(args?.currentRequest);
  };

  const setRequestHeader = (currentRequest?: XMLHttpRequest) => {
    if (!currentRequest || !accessToken) {
      return;
    }

    currentRequest.setRequestHeader("Authorization", `Bearer ${accessToken}`);
  };

  const onCompleteUploadingDefault = (doc: Document | null) => {
    if (doc != null) {
      fileFieldRef.current?.clearAll;
      !!onCompleteUploading && onCompleteUploading(doc, fileFieldRef.current!);
    } else {
      fileFieldRef.current?.cancel();
      fileFieldRef.current?.clearAll();

      AppToaster.show({
        message: <div>{genericErrorMessage}</div>,
        intent: Intent.DANGER,
      });
    }
  };

  // Config
  const defaultUploaderConfig = {
    enabled: !disabled,
    success: (doc) => {
      const docUploaded =
        doc.operation === "upload" ? JSON.parse(doc.e.target.response) : {};
      onSubmit(docUploaded);

      if (doc.operation === "upload") {
        onCompleteUploading &&
          onCompleteUploading(docUploaded, fileFieldRef.current!);
      } else if (doc.operation === "remove") {
        onCompleteRemoving && onCompleteRemoving();
      }
    },
    chunkFailure: (doc) => {
      const exceptionDetails = JSON.parse(
        doc.event.currentTarget.response
      ) as ExceptionDetails;
      if (exceptionDetails.type === ExceptionType.DocumentCorruptException) {
        doc.cancel = true;
        onCompleteUploadingDefault(null);
      }
    },
    chunkSuccess: (doc) => {
      if (doc.operation === "upload") {
        const docUploaded = JSON.parse(doc.e.target.response);

        onSubmit(docUploaded);
        onCompleteUploading &&
          onCompleteUploading(docUploaded, fileFieldRef.current!);
      } else if (doc.name === "chunkSuccess") {
        const docUploaded = JSON.parse(doc.event.target.response) as Document;
        lastChunk = docUploaded;
        onSubmit(docUploaded);
      } else {
        onSubmit({});
      }
    },
    sequentialUpload: true,
    uploading: onUploadingDefault,
    chunkUploading: onChunckUploadingDefault,
    removing: onRemovingDefault,
    actionComplete: (args) =>
      onAllActionComplete?.(args, fileFieldRef.current!),
  } as UploaderModel;

  const resolvedUploaderConfig = {
    ...defaultUploaderConfig,
    ...uploaderConfig,
  };

  return (
    <FormGroup className="base-field">
      <section className="field-header file">
        <span className="file-field label">
          {label && <SelectableLabel name={label} />}
        </span>
        {showPreview && (
          <span className="file-field preview">
            {value && (
              <Icon
                title="Preview"
                icon={IconNames.EyeOpen}
                onClick={() => {
                  dialogsViewerStore.setIsImageViewerDialogOpen(true, {
                    documentId: value,
                  });
                }}
              />
            )}
          </span>
        )}
      </section>
      <UploaderComponent
        id="documentFile"
        ref={fileFieldRef}
        {...resolvedUploaderConfig}
        maxFileSize={parseInt(variables.uploadFileSizeLimit)}
        locale="default"
      />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
