import { useEffect, useState } from "react";
import {
  AllFIARequestODataReadDTO,
  DocumentFilingAssociatedChecklistItem,
  FIARequestFilingProcessDocumentResult,
} from "./types";
import { Document, DocumentVerifyDTO } from "../../types/Document";
import {
  FIARequestFollowUpActionTypes,
  FIARequestFollowUpReasonTypes,
} from "../FIARequests/configurations/types";
import variables from "../../config/variables";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { AsyncAutoCompleteFieldProps } from "@ucl/library/lib/components/Fields/DropdownFields/AsyncAutoCompleteField/AsyncAutoCompleteField";
import { getAuthRequestHeader } from "../../lib/apiClients/baseApiClient";
import { fiaRequestApiClient } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { AppToaster } from "../Toast/Toast";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { Intent } from "@blueprintjs/core";
import { UseDocumentFilingProps } from "./useDocumentFiling";
import { startCase, toLower } from "lodash";
import { documentFilingAIClient } from "../../lib/apiClients/documentFilingAI/documentFilingAIClient";

const useFiaDocumentFiling = (props: UseDocumentFilingProps) => {
  const [isUploadingFiaDoc, setIsUploadingFiaDoc] = useState(false);
  const [fiaProcessDocumentResult, setFiaProcessDocumentResult] =
    useState<FIARequestFilingProcessDocumentResult>();
  const [fiaRequest, setFiaRequest] = useState<AllFIARequestODataReadDTO>();

  useEffect(() => {
    if (fiaRequest?.id) {
      fetchFIAAssociatedChecklistItem(fiaRequest);
      props.setDocumentVerifyDTO({
        ...props.documentVerifyDTO,
        applicationId: fiaRequest.applicationId,
        fiaRequestIds: [fiaRequest.id],
      });
      props.setApplicationId(fiaRequest.applicationId);
    } else {
      props.setAssociatedChecklistItem(undefined);
      props.setApplicationId(undefined);
    }
    const newDocumentName = getDocumentNameFromFiaResult(
      props.document,
      fiaRequest
    );
    props.setNewDocumentName(newDocumentName);
  }, [fiaRequest]);

  const processFIADocument = async (document: string) => {
    const result = await documentFilingAIClient
      .fiaFilingProcessDocument(document)
      .catch(() => {
        AppToaster.show({
          message: genericErrorMessage,
          intent: Intent.DANGER,
        });
      });

    if (result?.fiaRequestData?.id) {
      setFiaProcessDocumentResult({ ...result });
      setFiaRequest({ ...result.fiaRequestData });
    } else {
      const initSearchValue = result?.parsedDocumentData?.accountFirstname1
        ? `${result?.parsedDocumentData?.accountFirstname1} ${result?.parsedDocumentData?.accountLastname1}`
        : result?.parsedDocumentData?.institutionName || "";
      props.searchValueRef.current = startCase(toLower(initSearchValue));
      props.autoFillDataRef.current = result?.fiaRequestResults;
      setFiaProcessDocumentResult({
        ...({
          fiaRequestData: undefined,
          parsedDocumentData: undefined,
        } as FIARequestFilingProcessDocumentResult),
      });
    }
  };

  const fiaNeedsFollowUp =
    fiaRequest?.requestStatus_AsString !== "Canceled" &&
    fiaRequest?.requestStatus_AsString !== "Complete" &&
    fiaRequest?.requestStatus_AsString !== "Error";

  const isFiaFilingValid = (fiaRequestVerifyDTO: DocumentVerifyDTO) => {
    const {
      applicationId,
      fiaRequestIds,
      fiaRequestAction,
      followUpReason,
      followUpSecondaryReason,
    } = fiaRequestVerifyDTO;

    // No need to validate if the request is canceled/complete & does not need follow up
    if (!fiaNeedsFollowUp) {
      return true;
    }

    if (!applicationId || !fiaRequestIds?.length || !fiaRequestAction) {
      return false;
    }

    if (fiaRequestAction === FIARequestFollowUpActionTypes.Resend) {
      if (
        followUpReason ===
        FIARequestFollowUpReasonTypes.PreviousRequestHasBeenCorrected
      ) {
        return !!followUpSecondaryReason;
      }
      return !!followUpReason;
    }

    return true;
  };

  const fiaSearchAsyncAutoCompleteFieldProps: AsyncAutoCompleteFieldProps = {
    label: "Search",
    odataUrl: `${variables.apiBaseUrl}odata/allfiarequestsodata`,
    getAuthRequestHeader: getAuthRequestHeader,
    fieldNames: [
      "id",
      "applicationId",
      "checklistItemId",
      "requestStatus_AsString",
      "residentFullName",
      "institutionName",
      "requestNumber",
      "displayTitle",
    ],
    autoCompleteSettings: {
      fields: { text: "displayTitle", value: "id" },
      query: new Query()
        .select([
          "id",
          "applicationId",
          "checklistItemId",
          "requestStatus_AsString",
          "residentFullName",
          "institutionName",
          "requestNumber",
          "displayTitle",
        ])
        .where("requestStatus_AsString", "notEqual", "open") // filter out open requests
        .sortBy("institutionName")
        .take(50),
      headerTemplate:
        "<div class='fiafiling-content-form-search-results-header fia'><span>FIA Request Number</span><span>Status</span><span>Resident Name</span><span>Institution Name</span></div>",
      itemTemplate:
        "<div class='fiafiling-content-form-search-results-content fia'><span>${requestNumber}</span><span>${requestStatus_AsString}</span><span>${residentFullName}</span><span>${institutionName}</span></div>",
    },
    sortBy: "institutionName",
    searchBy: (searchText: string) =>
      new Predicate(
        new Predicate("requestNumber", "contains", searchText),
        "or",
        [
          new Predicate("institutionName", "contains", searchText),
          new Predicate("residentFullName", "contains", searchText),
        ]
      ),
    onSubmit: (value) => {
      setFiaRequest({
        ...(value as unknown as AllFIARequestODataReadDTO),
      });
    },
    submitAllAssigments: true,
  };

  const fetchFIAAssociatedChecklistItem = async (
    allFIARequestODataReadDTO: AllFIARequestODataReadDTO
  ) => {
    if (
      !(
        allFIARequestODataReadDTO?.applicationId &&
        allFIARequestODataReadDTO?.id
      )
    ) {
      return;
    }

    const fetchedAssociatedChecklistItem = await fiaRequestApiClient
      .getAssociatedChecklistItem(
        allFIARequestODataReadDTO?.applicationId,
        allFIARequestODataReadDTO?.id
      )
      .catch(() => {
        AppToaster.show({
          message: genericErrorMessage,
          intent: Intent.DANGER,
        });
      });

    if (fetchedAssociatedChecklistItem) {
      props.setAssociatedChecklistItem({ ...fetchedAssociatedChecklistItem });
    } else {
      props.setAssociatedChecklistItem({
        ...({
          displayName: "No associated checklist item",
        } as DocumentFilingAssociatedChecklistItem),
      });
    }
  };

  const submitFiaDocument = async (isBypass: boolean): Promise<boolean> => {
    if (
      !props.document?.id ||
      !fiaRequest?.id ||
      !props.associatedChecklistItem?.id
    ) {
      return false;
    }

    let result = false;
    await fiaRequestApiClient
      .createFiaFiling(fiaRequest.applicationId, fiaRequest.id, {
        documentId: props.document.id,
        documentName: props.newDocumentName || props.document.originalFileName,
        documentVerifyDTO: props.documentVerifyDTO,
        isBypass: isBypass,
      })
      .then(() => {
        AppToaster.show({
          message: "FIA Filing submitted successfully",
          intent: Intent.SUCCESS,
          action: {
            text: "Go to FIA Request",
            onClick: () => {
              window.open(
                `/application-page/${fiaRequest?.applicationId}/fia-requests/open/${fiaRequest?.id}`,
                "_blank"
              );
            },
          },
        });

        result = true;
      })
      .catch(() => {
        AppToaster.show({
          message: genericErrorMessage,
          intent: Intent.DANGER,
        });
        result = false;
      });

    return result;
  };

  const getDocumentNameFromFiaResult = (
    document: Document | undefined,
    fiaRequestResult: AllFIARequestODataReadDTO | undefined
  ) => {
    if (!document) {
      return "-";
    }

    const institutionName = fiaRequestResult?.institutionName;
    const checklistDocumentType = fiaRequestResult?.checklistDocumentType;

    const newDocumentName = institutionName
      ? checklistDocumentType
        ? `${institutionName} - ${checklistDocumentType}.pdf`
        : `${institutionName}.pdf`
      : document.originalFileName;

    return newDocumentName || "-";
  };

  return {
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    fiaProcessDocumentResult,
    setFiaProcessDocumentResult,
    fiaRequest,
    setFiaRequest,
    isFiaFilingValid,
    fiaSearchAsyncAutoCompleteFieldProps,
    submitFiaDocument,
    processFIADocument,
    fiaNeedsFollowUp,
  };
};

export default useFiaDocumentFiling;
